<template>
  <div id="login-page">
    <img class="img-logo" alt="logo image" src="@/assets/images/logo.png" />
    <h2 class="mt-3 fw-bold">{{ title }}</h2>
    <form class="mt-3" @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model="emailInput"
          required
        />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Mật khẩu</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="passwordInput"
          required
        />
      </div>
      <template v-if="errorMsg.length > 0">
        <div class="msg-err">{{ errorMsg }}</div>
      </template>
      <div class="submit-btn">
        <button
          type="submit"
          class="common-btn-submit btn btn-primary mt-3 py-2 px-4"
        >
          Đăng nhập
        </button>
        <a
          href=""
          @click="$router.push('/forget-password')"
          class="forgot-password mt-3"
          >Quên mật khẩu?</a
        >
      </div>
    </form>
  </div>
</template>

<script>
import { requestAsync } from "@/requester/requestAsync";
// import { jwtDecode } from "jwt-decode";
import { mapMutations } from "vuex";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      emailInput: "",
      passwordInput: "",
      errorMsg: "",
    };
  },
  methods: {
    ...mapMutations("customer", ["setToken", "setMemberInfo"]),
    getMemberId(decoded) {
      return decoded?.data?.objectId;
    },
    async submitForm() {
      let reqData = {
        username: this.emailInput,
        password: this.passwordInput,
      };
      let res = await requestAsync.post("auth/sign-in", reqData);
      if (res?.token) {
        this.setToken(res?.token);
        this.setMemberInfo(res?.member);
        this.errorMsg = "";
        this.$router.push("/user-management");
      } else {
        this.errorMsg = "Đăng nhập thất bại, vui lòng đăng nhập lại!";
      }
    },
  },
};
</script>

<style lang="scss">
#login-page {
  width: 100%;
  max-width: 500px;
  margin: 0px auto;
  margin-top: 100px;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 10px 10px 40px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  .img-logo {
    max-width: 350px;
    margin: auto;
  }
  .submit-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .forgot-password {
      margin-left: auto;
      margin-top: 10px;
      color: #007bff;
      text-decoration: none;
    }
  }
}
</style>
