var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"saveGroupProductModal",attrs:{"hide-header":"","hide-footer":"","id":"modal-save-group-product"}},[_c('div',{staticClass:"save-group-product-modal"},[_c('div',{staticClass:"header-save-product"},[_c('span',[_vm._v("LƯU NHÓM")])]),_c('hr'),_c('div',{staticClass:"body-save-product"},[_c('div',{staticClass:"column"},[_c('button',{staticClass:"common-btn select-btn",on:{"click":() => {
              _vm.showCreateNew = false;
              _vm.showEdit = true;
            }}},[_vm._v(" Vào nhóm có sẵn ")]),(_vm.showEdit)?_c('b-form-select',{attrs:{"options":_vm.groupOptions},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}}):_vm._e()],1),_c('div',{staticClass:"or"},[_vm._v("OR")]),_c('div',{staticClass:"column"},[_c('button',{staticClass:"common-btn select-btn",on:{"click":() => {
              _vm.showCreateNew = true;
              _vm.showEdit = false;
            }}},[_vm._v(" Tạo mới ")]),(_vm.showCreateNew)?_c('div',[_c('b-form-input',{staticClass:"mt-2",attrs:{"placeholder":"Nhập tên group mới"},model:{value:(_vm.newGroupName),callback:function ($$v) {_vm.newGroupName=$$v},expression:"newGroupName"}})],1):_vm._e()])]),_c('hr'),_c('div',{staticClass:"footer-save-product"},[_c('button',{staticClass:"common-btn",on:{"click":_vm.saveGroup}},[_vm._v("Lưu")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }