var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"dashboard-comp"}},[_c('div',{staticClass:"list-page"},[_c('div',{staticClass:"user-management-page item-page",class:{ 'active-item-page': _vm.isMatchCurrentUrl(_vm.USER_MANAGEMENT.url) },on:{"click":function($event){return _vm.redirectTo(_vm.USER_MANAGEMENT.url)}}},[_c('i',{staticClass:"fa fa-users icon-dashboard"})]),_c('div',{staticClass:"building-management-page item-page",class:{
        'active-item-page': _vm.isMatchCurrentUrl(_vm.BUILDING_MANAGEMENT.url),
      },on:{"click":function($event){return _vm.redirectTo(_vm.BUILDING_MANAGEMENT.url)}}},[_c('i',{staticClass:"fa fa-institution icon-dashboard"})]),_c('div',{staticClass:"building-management-page item-page",class:{
        'active-item-page': _vm.isMatchCurrentUrl(_vm.GROUP_MANAGEMENT.url),
      },on:{"click":function($event){return _vm.redirectTo(_vm.GROUP_MANAGEMENT.url)}}},[_c('i',{staticClass:"fa fa-folder-open-o icon-dashboard"})]),(this.isAdmin)?_c('div',{staticClass:"member-management-page item-page",class:{
        'active-item-page': _vm.isMatchCurrentUrl(_vm.MEMBER_MANAGEMENT.url),
      },on:{"click":function($event){return _vm.redirectTo(_vm.MEMBER_MANAGEMENT.url)}}},[_c('i',{staticClass:"fa fa-address-book icon-dashboard"})]):_vm._e(),(this.isAdmin)?_c('div',{staticClass:"member-management-page item-page",class:{
        'active-item-page': _vm.isMatchCurrentUrl(_vm.NOTIFICATION_MANAGEMENT.url),
      },on:{"click":function($event){return _vm.redirectTo(_vm.NOTIFICATION_MANAGEMENT.url)}}},[_c('i',{staticClass:"fa fa-calendar-o icon-dashboard"})]):_vm._e(),_c('div',{staticClass:"personal-management-page item-page",class:{
        'active-item-page': _vm.isMatchCurrentUrl(_vm.PERSONAL_INFO.url),
      },on:{"click":function($event){return _vm.redirectTo(_vm.PERSONAL_INFO.url)}}},[_c('i',{staticClass:"fa fa-drivers-license-o icon-dashboard"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }