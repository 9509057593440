<template>
  <div id="personal-info-page" class="w-100">
    <PersonalInfoComp></PersonalInfoComp>
  </div>
</template>

<script>
import PersonalInfoComp from "@/components/PersonalInfoComp.vue";
export default {
  components: { PersonalInfoComp },
};
</script>

<style></style>
