<template>
  <div id="app">
    <template v-if="!isLoginListPage">
      <HeaderComp></HeaderComp>
    </template>
    <div class="d-flex flex-row justify-content-center">
      <template v-if="!isLoginListPage">
        <DashboardComp></DashboardComp>
      </template>
      <router-view class="flex-grow-1 full-height" />
    </div>
  </div>
</template>
<script>
import DashboardComp from "./components/DashboardComp.vue";
import HeaderComp from "./components/HeaderComp.vue";
export default {
  data() {
    return {
      LIST_URL_LOGIN: ["/login", "/register", "/forget-password"],
    };
  },
  components: { DashboardComp, HeaderComp },
  computed: {
    isLoginListPage() {
      return this.LIST_URL_LOGIN.find(
        (element) => element === this.$route.path
      );
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");
@import "./assets/variable.scss";
@import "./assets/common-class.scss";
#app {
  font-family: "Roboto", sans-serif;
  background: #f5f5f5;
  min-height: 100vh;
  .full-height {
    min-height: calc(100vh - 64px);
  }
}
</style>
