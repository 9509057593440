<template>
  <b-modal
    hide-header
    hide-footer
    id="modal-1"
    ref="modalInnerUserInfo"
    @hidden="resetData"
  >
    <div class="add-user-modal">
      <div class="header-add-user">
        <span>THÊM KHÁCH HÀNG</span>
      </div>
      <div class="body-add-user">
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="user_name" class="form-label">Tên khách hàng</label>
            <input
              type="input"
              class="form-control"
              id="user_name"
              v-model="userInfoDataShow.name"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="company_name" class="form-label">Tên công ty</label>
            <input
              type="input"
              class="form-control"
              id="company_name"
              v-model="userInfoDataShow.company_name"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="phone_number" class="form-label">Số điện thoại</label>
            <input
              type="input"
              class="form-control"
              id="phone_number"
              v-model="userInfoDataShow.phone"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="email" class="form-label">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="userInfoDataShow.email"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="created_at" class="form-label">Ngày nhập</label>
            <input
              type="date"
              class="form-control"
              id="created_at"
              v-model="userInfoDataShow.member_create_customer_at"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="updated_at" class="form-label">CSKH gần nhất</label>
            <input
              type="date"
              class="form-control"
              id="updated_at"
              v-model="customerCareAtUpdated"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="acreage_require" class="form-label">Diện tích</label>
            <input
              type="input"
              class="form-control"
              id="acreage_require"
              v-model="userInfoDataShow.demand.expect_area"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="used_time_require" class="form-label"
              >Thời gian sử dụng</label
            >
            <input
              type="input"
              class="form-control"
              id="used_time_require"
              v-model="userInfoDataShow.demand.used_time"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="budget_require" class="form-label">Ngân sách</label>
            <input
              type="input"
              class="form-control"
              id="budget_require"
              v-model="userInfoDataShow.demand.budget"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="location_require" class="form-label">Khu vực</label>
            <input
              type="input"
              class="form-control"
              id="location_require"
              v-model="userInfoDataShow.demand.address.expect_location"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="total_member_require" class="form-label"
              >Số lượng nhân viên</label
            >
            <input
              type="input"
              class="form-control"
              id="total_member_require"
              v-model="userInfoDataShow.demand.num_person"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="total_trans" class="form-label"
              >Số lượng p/tiện (xe máy, ô tô)</label
            >
            <input
              type="input"
              class="form-control"
              id="total_trans"
              v-model="userInfoDataShow.demand.vehicle.car"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item d-flex flex-column">
            <label for="current_home" class="form-label"
              >Đang ở toà nhà nào</label
            >
            <input
              type="input"
              class="form-control"
              id="current_home"
              v-model="userInfoDataShow.current_home"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="special_note" class="form-label"
              >Yêu cầu đặc biệt</label
            >
            <input
              type="input"
              class="form-control"
              id="special_note"
              v-model="userInfoDataShow.special_note"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="total_trans" class="form-label">Timeline</label>
            <input
              type="date"
              class="form-control"
              id="total_trans"
              v-model="userInfoDataShow.status_updated_at"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="d-flex flex-column">
            <label for="total_member_require" class="form-label"
              >Nhân viên chăm sóc</label
            >
            <MultiSelect
              placeholder="Vui lòng lựa chọn người chăm sóc"
              v-model="selectedMemberSale"
              :multiple="true"
              :options="optionsMemberSale"
            >
            </MultiSelect>
          </div>
        </div>
        <div class="mt-2">
          <div class="user-info-item">
            <label for="user_name" class="form-label">Ghi chú</label>
            <textarea
              class="form-control"
              rows="3"
              id="user_name"
              v-model="userInfoDataShow.note"
              required
            >
            </textarea>
          </div>
        </div>
        <div class="error-message mt-2">
          <span class="text-danger">{{ errorMessage }}</span>
        </div>
        <div class="mt-4 mb-3" v-if="isShowBtnAddMember && !isAdmin">
          <div class="button-submit d-flex flex-row justify-content-center">
            <button
              @click="cancelAddMember()"
              class="common-btn clear-btn mx-auto"
            >
              Bỏ qua
            </button>
            <button @click="submitAddMember()" class="common-btn mx-auto">
              Chăm sóc
            </button>
          </div>
        </div>
        <div class="mt-3">
          <div class="button-submit d-flex flex-row justify-content-center">
            <button @click="submitUserInfo()" class="common-btn mx-auto">
              {{ title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";
import { requestAsync } from "@/requester/requestAsync";
import { mapGetters } from "vuex";
export default {
  props: {
    userInfo: Object,
    title: String,
  },
  data() {
    return {
      userInfoDataShow: null,
      originalUserInfo: null,
      isUpdateUser: false,
      customerCareAtUpdated: moment().format("YYYY-MM-DD"),
      memberSales: [],
      selectedMemberSale: [],
      optionsMemberSale: [],
      listMemberSale: [],
      STATUS_OPTIONS_CHECKBOX: [
        {
          text: "Mới",
          value: "NEW",
        },
        {
          text: "Khó chăm sóc",
          value: "INACCESSIBLE",
        },
        {
          text: "Báo giá",
          value: "QUOTE",
        },
        {
          text: "Đi xem",
          value: "WATCHED",
        },
        {
          text: "Thương lượng",
          value: "NEGOTIATION",
        },
        {
          text: "Tạm hoãn",
          value: "PENDING",
        },
        {
          text: "Thành công",
          value: "SUCCESS",
        },
        {
          text: "Thất bại",
          value: "FAIL",
        },
      ],
      errorMessage: "",
      initNewMemberInfo: {
        id: 0,
        created_at: "",
        updated_at: "",
        deleted_at: 0,
        username: "",
        role: "",
        phone: "",
        name: "",
        connection_info: {
          first_step: "",
          second_step: "",
          third_step: "",
          fourth_step: "",
          fifth_step: null,
          status: "NEW",
          status_updated_at: null,
        },
      },
      isShowBtnAddMember: false,
      dataUserExist: {},
    };
  },
  watch: {
    userInfo: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$set(
          this,
          "userInfoDataShow",
          JSON.parse(JSON.stringify(this.userInfo))
        );
        this.$set(
          this,
          "originalUserInfo",
          JSON.parse(JSON.stringify(this.userInfo))
        );
        if (this.userInfoDataShow.id) {
          this.isUpdateUser = true;
        }
        this.userInfoDataShow.member_create_customer_at = this.formatDate(
          this.userInfoDataShow.member_create_customer_at
        );
        this.selectedMemberSale = this.userInfoDataShow?.member_infos?.map(
          (item) => item.name
        );
      },
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    resetData() {
      this.userInfoDataShow = JSON.parse(JSON.stringify(this.originalUserInfo));
      if (this.userInfoDataShow.id) {
        this.isUpdateUser = true;
      }
      this.userInfoDataShow.member_create_customer_at = this.formatDate(
        this.userInfoDataShow.member_create_customer_at
      );
      this.selectedMemberSale = this.userInfoDataShow?.member_infos?.map(
        (item) => item.name
      );
      this.errorMessage = "";
      this.isShowBtnAddMember = false;
      this.dataUserExist = {};
    },
    validateUserInfo() {
      if (!this.selectedMemberSale || this.selectedMemberSale.length < 1) {
        this.errorMessage = "Vui lòng chọn người chăm sóc";
        return false;
      }
      return true;
    },
    initMemberSaleInfosToCreate() {
      if (!this.userInfoDataShow.member_infos)
        this.userInfoDataShow.member_infos = [];
      for (let sale of this.selectedMemberSale) {
        let saleInfo;
        if (this.isAdmin) {
          saleInfo = this.listMemberSale.find((item) => item.name === sale);
        } else {
          saleInfo = this.getMemberInfo;
        }
        // only add sale not exist in member_infos
        if (
          this.userInfoDataShow.member_infos &&
          this.userInfoDataShow.member_infos.findIndex(
            (item) => item.name === sale
          ) === -1
        ) {
          let init = JSON.parse(JSON.stringify(this.initNewMemberInfo));
          // Assign matching keys
          for (let key in init) {
            if (saleInfo[key] !== undefined) {
              init[key] = saleInfo[key];
            }
          }
          this.userInfoDataShow.member_infos.push(init);
        }
      }
      for (let sale of this.userInfoDataShow.member_infos) {
        if (!this.selectedMemberSale.includes(sale.name)) {
          this.userInfoDataShow.member_infos =
            this.userInfoDataShow.member_infos.filter(
              (item) => item.name !== sale.name
            );
        }
      }
    },
    initMemberSaleInfosWhenAcceptCare() {
      let saleInfo = this.getMemberInfo;
      if (
        this.dataUserExist.member_infos.findIndex(
          (item) => item.name === saleInfo.name
        ) === -1
      ) {
        let initConnectionInfo = JSON.parse(
          JSON.stringify(this.initNewMemberInfo.connection_info)
        );
        // Assign matching keys
        for (let key in initConnectionInfo) {
          console.log(key);
          if (saleInfo[key] !== undefined) {
            initConnectionInfo[key] = saleInfo[key];
          }
        }
        console.log(initConnectionInfo);
        this.dataUserExist.connection_info = initConnectionInfo;
      }
    },
    submitUserInfo() {
      this.isShowBtnAddMember = false;
      let isCheck = this.validateUserInfo();
      if (!isCheck) return;
      this.userInfoDataShow.customer_care_at = moment(
        this.customerCareAtUpdated
      )
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      this.userInfoDataShow.name = this.userInfoDataShow.name.trim();
      this.userInfoDataShow.phone = this.userInfoDataShow.phone.trim();
      this.userInfoDataShow.email = this.userInfoDataShow.email.trim();
      this.userInfoDataShow.company_name =
        this.userInfoDataShow.company_name.trim();
      this.userInfoDataShow.member_create_customer_at = moment(
        this.userInfoDataShow.member_create_customer_at
      )
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      this.initMemberSaleInfosToCreate();
      let data = JSON.parse(JSON.stringify(this.userInfoDataShow));
      if (this.isUpdateUser) {
        this.$emit("updateUserInfo", data);
      } else {
        this.createNewUser();
      }
    },
    async createNewUser() {
      let res = await requestAsync.post("customers", this.userInfoDataShow);
      if (!res?.error) {
        this.$toast.open({
          message: "Tạo khách hàng mới thành công!",
          type: "success",
          position: "top",
          duration: 2000,
        });
        this.$emit("updateUserInfo");
        this.$refs.modalInnerUserInfo.hide();
      } else if (res.error === "duplicated key not allowed") {
        this.errorMessage = "Khách hàng đã tồn tại trong hệ thống!";
        this.isShowBtnAddMember = true;
        this.dataUserExist = res.customer;
      }
    },
    async getMemberSalesInfo() {
      if (this.isAdmin) {
        let res = await requestAsync.get("members");
        if (res.items) {
          this.memberSales = res.items;
          res.items.forEach((item) => {
            item.updated_at = moment(item.updated_at).format("DD-MM-YYYY");
          });
          this.selectedMemberSale = this.userInfoDataShow?.member_infos?.map(
            (item) => item.name
          );
          this.listMemberSale = res.items;
          this.optionsMemberSale = res.items
            .sort((a, b) => {
              return new Date(a.created_at) - new Date(b.created_at);
            })
            .filter((item) => item.role === "MEMBER")
            .map((item) => item.name);
        }
      } else {
        this.listMemberSale = [this.getMemberInfo];
        this.optionsMemberSale = this.listMemberSale
          .sort((a, b) => {
            return new Date(a.created_at) - new Date(b.created_at);
          })
          .filter((item) => item.role === "MEMBER")
          .map((item) => item.name);
      }
    },
    submitAddMember() {
      this.initMemberSaleInfosWhenAcceptCare();
      let res = requestAsync.put(
        "customers/" + this.dataUserExist.id + "/self",
        this.dataUserExist
      );
      if (!res?.error) {
        this.$toast.open({
          message: "Thêm nhân viên chăm sóc thành công!",
          type: "success",
          position: "top",
          duration: 2000,
        });
        this.$emit("update-user-info");
        this.$refs.modalInnerUserInfo.hide();
        this.postNotification(
          "Đồng ý chăm sóc khách hàng",
          `Nhân viên ${this.getMemberInfo.name} đã xác nhận chăm sóc khách hàng ${this.dataUserExist.name} có sđt: ${this.dataUserExist.phone}`
        );
      }
    },
    cancelAddMember() {
      this.$refs.modalInnerUserInfo.hide();
      this.postNotification(
        "Từ chối chăm sóc khách hàng",
        `Nhân viên ${this.getMemberInfo.name} từ chối chăm sóc khách hàng ${this.dataUserExist.name} có sđt: ${this.dataUserExist.phone}`
      );
    },

    async postNotification(title, body) {
      let res = await requestAsync.post("notifications", {
        title: title,
        body: body,
      });
      console.log(res);
    },
  },
  computed: {
    // Add your computed properties here
    ...mapGetters("customer", ["getMemberInfo", "isAdmin"]),
  },
  created() {
    this.getMemberSalesInfo();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "@/assets/variable.scss";
// modal
.modal-body {
  padding: 0 !important;
}
.add-user-modal {
  .header-add-user {
    text-align: center;
    background: rgba($primary-color, 0.65);
    color: white;
    padding: 15px 0px;
    font-size: 18px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .body-add-user {
    padding: 15px;
    label {
      color: $primary-color;
    }
    .row-user-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .user-info-item {
        width: 48%;
      }
      .w-30 {
        width: 30%;
      }
    }
    .custom-select {
      padding: 5px;
      width: 100%;
    }
  }
}
.error-message {
  text-align: center;
  span {
    font-size: 20px;
    font-weight: bold;
    color: rgb(0, 59, 136) !important;
  }
}
</style>
