const namespaced = true;

const state = {
  token: "",
  member_id: "",
  memberInfo:
    JSON.parse(window.localStorage.getItem("crm-customer") || "{}")
      .memberInfo || null,
};
const getters = {
  getToken(state) {
    let localState = window.localStorage.getItem("crm-customer");
    if (localState) {
      state.token = JSON.parse(localState).token;
    }
    return state.token;
  },
  getMemberId(state) {
    let localState = window.localStorage.getItem("crm-customer");
    if (localState) {
      state.member_id = JSON.parse(localState).member_id;
    }
    return state.member_id;
  },
  getMemberInfo(state) {
    return state.memberInfo;
  },
  isAdmin(state) {
    let localState = window.localStorage.getItem("crm-customer");
    if (localState) {
      state.memberInfo = JSON.parse(localState).memberInfo;
    }
    if (state.memberInfo.role === "ADMIN") {
      return true;
    } else {
      return false;
    }
  },
};
const mutations = {
  setToken(state, token) {
    state.token = token;
    let saveState = JSON.stringify(state);
    window.localStorage.setItem("crm-customer", saveState);
    return state.token;
  },
  setMemberId(state, member_id) {
    state.member_id = member_id;
    let saveState = JSON.stringify(state);
    window.localStorage.setItem("crm-customer", saveState);
    return state.member_id;
  },
  setMemberInfo(state, memberInfo) {
    state.memberInfo = memberInfo;
    let saveState = JSON.stringify(state);
    window.localStorage.setItem("crm-customer", saveState);
    return state.memberInfo;
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
};
