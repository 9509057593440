<template>
  <b-modal
    hide-header
    hide-footer
    id="modal-add-product"
    ref="modalProductInfo"
  >
    <div class="add-product-modal">
      <div class="header-add-user">
        <span>THÊM SẢN PHẨM</span>
      </div>
      <div class="body-add-user">
        <div class="d-flex flex-row">
          <div class="left-body-add-product">
            <span style="color: red"
              >(Vui lòng điền những phần đánh dấu *)</span
            >

            <div class="product-info-item mt-4">
              <label for="user_name" class="form-label">Tên sản phẩm *</label>
              <input
                type="input"
                class="form-control"
                id="user_name"
                v-model="productInfoData.name"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="website_link" class="form-label">Link website</label>
              <input
                type="input"
                class="form-control"
                id="website_link"
                v-model="productInfoData.website"
              />
            </div>
            <div class="product-info-item">
              <label for="owner" class="form-label">Chủ đầu tư</label>
              <input
                type="input"
                class="form-control"
                id="owner"
                v-model="productInfoData.product_detail.owner"
              />
            </div>
            <div class="product-info-item">
              <label for="management_unit" class="form-label"
                >Đơn vị quản lý</label
              >
              <input
                type="input"
                class="form-control"
                id="management_unit"
                v-model="productInfoData.management_unit"
              />
            </div>
            <div class="product-info-item">
              <label for="phone_number" class="form-label"
                >Kết cấu & thang máy</label
              >
              <input
                type="input"
                class="form-control"
                id="phone_number"
                v-model="productInfoData.product_detail.structure"
              />
            </div>
            <div class="product-info-item">
              <label for="user_name" class="form-label">Số nhà</label>
              <input
                type="input"
                class="form-control"
                id="user_name"
                v-model="productInfoData.address.detail"
                required
              />
            </div>
            <div class="product-info-item d-flex flex-column">
              <label for="user_name" class="form-label">Phường*</label>
              <b-form-select
                v-model="productInfoData.address.ward"
                :options="listCommuneOptions"
              ></b-form-select>
            </div>
            <div class="product-info-item d-flex flex-column">
              <label for="user_name" class="form-label">Quận*</label>
              <b-form-select
                v-model="productInfoData.address.district"
                :options="listDistrictOptions"
              ></b-form-select>
            </div>
            <div class="product-info-item d-flex flex-column">
              <label for="user_name" class="form-label">Tỉnh (TP)*</label>
              <b-form-select
                v-model="productInfoData.address.province"
                :options="listProvinceOptions"
              ></b-form-select>
            </div>
            <div class="product-info-item">
              <label for="celling_height" class="form-label"
                >Độ cao trần*</label
              >
              <input
                type="input"
                class="form-control"
                id="celling_height"
                v-model="productInfoData.product_detail.celling_height"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="created_at" class="form-label"
                >Diện tích cho thuê (Số)*</label
              >
              <input
                type="input"
                class="form-control"
                id="created_at"
                v-model="productInfoData.product_detail.empty_area"
                required
              />
            </div>
            <div class="product-info-item w-100">
              <label for="created_at" class="form-label"
                >Diện tích cho thuê (Mô tả)</label
              >
              <input
                type="input"
                class="form-control"
                id="created_at"
                v-model="productInfoData.product_detail.empty_area_detail"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="acreage_require" class="form-label"
                >Diện tích sàn (Số) (m2)</label
              >
              <input
                type="text"
                class="form-control"
                id="acreage_require"
                v-model="productInfoData.product_detail.floor_area"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="used_time_require" class="form-label">Hướng</label>
              <b-form-select
                v-model="productInfoData.product_detail.building_direction"
                :options="listDirectionOptions"
              ></b-form-select>
            </div>
            <div class="product-info-item">
              <label for="used_time_require" class="form-label">Hạng</label>
              <b-form-select
                v-model="productInfoData.rank"
                :options="listRank"
              ></b-form-select>
            </div>
            <div class="product-info-item">
              <label for="location_require" class="form-label"
                >Năm hoàn thành</label
              >
              <input
                type="input"
                class="form-control"
                id="location_require"
                v-model="productInfoData.product_detail.completion_time"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="budget_require" class="form-label"
                >Giá thuê (Số) ($/m2)</label
              >
              <input
                type="input"
                class="form-control"
                id="budget_require"
                v-model="productInfoData.rent_detail.basic_rent_cost"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="location_require" class="form-label"
                >Phí dịch vụ (Số) ($/m2)</label
              >
              <input
                type="text"
                class="form-control"
                id="location_require"
                v-model="productInfoData.rent_detail.service_cost"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="total_member_require" class="form-label"
                >Phí xe mô tô (/xe/tháng)</label
              >
              <input
                type="text"
                class="form-control"
                id="total_member_require"
                v-model="productInfoData.rent_detail.motor_park_cost"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="total_trans" class="form-label"
                >Phí xe ô tô (/xe/tháng)</label
              >
              <input
                type="text"
                class="form-control"
                id="total_trans"
                v-model="productInfoData.rent_detail.car_park_cost"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="total_trans" class="form-label">Phí ngoài giờ</label>
              <input
                type="input"
                class="form-control"
                id="total_trans"
                v-model="productInfoData.rent_detail.over_time_cost"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="total_member_require" class="form-label"
                >Tiền điện</label
              >
              <input
                type="input"
                class="form-control"
                id="total_member_require"
                v-model="productInfoData.rent_detail.electronic_cost"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="total_trans" class="form-label">Tiền đặt cọc</label>
              <input
                type="input"
                class="form-control"
                id="total_trans"
                v-model="productInfoData.rent_detail.rent_deposit"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="total_trans" class="form-label">Thanh toán</label>
              <input
                type="input"
                class="form-control"
                id="total_trans"
                v-model="productInfoData.rent_detail.rent_area"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="total_member_require" class="form-label"
                >Thời hạn thuê</label
              >
              <input
                type="input"
                class="form-control"
                id="total_member_require"
                v-model="productInfoData.rent_detail.rent_time"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="total_trans" class="form-label"
                >Thời gian trang trí</label
              >
              <input
                type="input"
                class="form-control"
                id="total_trans"
                v-model="productInfoData.rent_detail.decor_time"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="total_trans" class="form-label">Phí môi giới</label>
              <input
                type="input"
                class="form-control"
                id="total_trans"
                v-model="productInfoData.rent_detail.brokerage_fee"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="lat" class="form-label">Vĩ độ</label>
              <input
                type="number"
                class="form-control"
                id="lat"
                v-model="productInfoData.address.lat"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="lng" class="form-label">Kinh độ</label>
              <input
                type="number"
                class="form-control"
                id="lat"
                v-model="productInfoData.address.lng"
                required
              />
            </div>
            <div class="product-info-item">
              <label for="manager_name" class="form-label">Tên quản lý</label>
              <input
                type="input"
                class="form-control"
                id="manager_name"
                v-model="productInfoData.manager_name"
              />
            </div>
            <div class="product-info-item">
              <label for="manager_phone" class="form-label"
                >Số điện thoại quản lý</label
              >
              <input
                type="input"
                class="form-control"
                id="manager_phone"
                v-model="productInfoData.manager_phone"
              />
            </div>
          </div>
          <div class="right-body-add-product flex-grow-1">
            <div class="add-file d-flex flex-column mt-5">
              <div class="d-flex flex-row justify-content-between">
                <label for="total_member_require" class="form-label"
                  >Hình ảnh sản phẩm</label
                >
                <button
                  @click="removeImage"
                  v-if="base64Image"
                  class="common-btn delete-btn mx-3"
                >
                  Xoá hình
                </button>
              </div>
              <input
                type="file"
                ref="fileImageInput"
                style="display: none"
                @change="handleImageUpload"
              />
              <img
                v-if="!base64Image"
                class="mt-3 upload-img"
                :src="require('@/assets/images/drap_img.png')"
                alt="Uploaded Image"
                @click="openFileInputImage"
              />
              <img
                v-if="base64Image"
                class="mt-3"
                :src="base64Image"
                alt="Uploaded Image"
              />
            </div>
            <div class="add-file d-flex flex-column mt-3">
              <label for="total_trans" class="form-label"
                >Tài nguyên sản phẩm</label
              >
              <input
                type="file"
                ref="fileDataInput"
                style="display: none"
                @change="handleFileUpload"
              />
              <div class="file-name" v-for="(file, idx) in listFile" :key="idx">
                {{ file.name }}
              </div>
              <img
                v-if="listFile.length < 1"
                class="mt-3 upload-img"
                :src="require('@/assets/images/drap_img.png')"
                alt="Uploaded File"
                @click="openFileInputFile"
              />
            </div>
            <div class="product-info-item">
              <label for="manager_name" class="form-label"
                >Người phụ trách</label
              >
              <b-form-select
                v-model="productInfoData.saler_name"
                :options="optionsMemberSale"
              ></b-form-select>
            </div>
            <div class="product-info-item">
              <label for="note" class="form-label">Ghi chú</label>
              <textarea class="w-100" rows="4" v-model="productInfoData.note">
              </textarea>
            </div>
          </div>
        </div>
        <template v-if="msgError.length > 0">
          <div
            style="color: red"
            class="mt-3 d-flex flex-row justify-content-center"
          >
            <span class="mx-auto">{{ msgError }}</span>
          </div>
        </template>
        <button @click="submitProductInfo()" class="common-btn mx-auto mt-3">
          {{ title }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import listDirectionOptions from "@/ultils/listDirection.js";
import listRank from "@/ultils/listRank.js";
import { requestAsync } from "@/requester/requestAsync";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  props: {
    productInfo: Object,
    title: String,
  },
  data() {
    return {
      productInfoData: {},
      base64Image: "",
      listFile: [],
      inputImage: null,
      msgError: "",
      apiUrl:
        "https://vietnam-administrative-division-json-server-swart.vercel.app",
      listProvince: [],
      listDistrict: [],
      listCommune: [],
      selectedProvinceId: null,
      selectedDistrictId: null,
      selectedCommuneId: null,
      listDirectionOptions: listDirectionOptions,
      listRank: listRank,
      optionsMemberSale: [],
    };
  },
  watch: {
    productInfo: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$set(this, "productInfoData", this.productInfo);
      },
    },
    "productInfoData.address.province": {
      immediate: true,
      deep: true,
      async handler() {
        await this.getProvince();
        this.selectedProvinceId = this.listProvince.filter((province) => {
          return province.name === this.productInfoData.address.province;
        })[0]?.idProvince;
      },
    },
    selectedProvinceId: {
      immediate: true,
      async handler() {
        if (this.selectedProvinceId) {
          await this.getDistrict();
        }
      },
    },
    "productInfoData.address.district": {
      immediate: true,
      deep: true,
      async handler() {
        this.selectedDistrictId = this.listDistrict.filter((district) => {
          return district.name === this.productInfoData.address.district;
        })[0]?.idDistrict;
      },
    },
    listDistrict: {
      immediate: true,
      deep: true,
      async handler() {
        this.selectedDistrictId = this.listDistrict.filter((district) => {
          return district.name === this.productInfoData.address.district;
        })[0]?.idDistrict;
      },
    },
    selectedDistrictId: {
      immediate: true,
      async handler() {
        if (this.selectedDistrictId) {
          await this.getCommune();
        }
      },
    },
    "productInfoData.address.ward": {
      immediate: true,
      deep: true,
      async handler() {
        this.selectedCommuneId = this.listCommune.filter((commune) => {
          return commune.name === this.productInfoData.address.ward;
        })[0]?.idCommune;
      },
    },
    listCommune: {
      immediate: true,
      deep: true,
      async handler() {
        this.selectedCommuneId = this.listCommune.filter((commune) => {
          return commune.name === this.productInfoData.address.ward;
        })[0]?.idCommune;
      },
    },
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0]; // Get the selected file
      this.inputImage = file;
      const reader = new FileReader();

      reader.onload = () => {
        this.base64Image = reader.result; // Store base64 representation of the image
      };

      if (file) {
        reader.readAsDataURL(file); // Convert selected image to base64
      }
    },
    removeImage() {
      (this.inputImage = null), (this.base64Image = "");
    },
    handleFileUpload(event) {
      const file = event.target.files[0]; // Get the selected file
      this.listFile.push(file);
    },
    openFileInputImage() {
      // Trigger the click event of the file input element
      this.$refs.fileImageInput.click();
    },
    openFileInputFile() {
      // Trigger the click event of the file input element
      this.$refs.fileDataInput.click();
    },
    calculateFullRent() {
      let basicRentCost = !isNaN(
        Number(this.productInfoData?.rent_detail?.basic_rent_cost)
      )
        ? Number(this.productInfoData?.rent_detail?.basic_rent_cost)
        : 0;
      let serviceCost = !isNaN(
        Number(this.productInfoData?.rent_detail?.service_cost)
      )
        ? Number(this.productInfoData?.rent_detail?.service_cost)
        : 0;
      this.productInfoData.rent_detail.full_rent_cost = String(
        basicRentCost + serviceCost
      );
    },
    submitProductInfo() {
      let isFullData = true;
      this.msgError = "";
      if (!isFullData) {
        this.msgError = "Vui lòng nhập thông tin đầy đủ!";
      } else {
        // this.productInfoData.address.lat = parseFloat(
        //   this.productInfoData.address.lat
        // );
        // this.productInfoData.address.lng = parseFloat(
        //   this.productInfoData.address.lng
        // );
        this.calculateFullRent();
        let data = {
          listFile: this.listFile,
          inputImage: this.inputImage,
          productInfo: this.productInfoData,
        };
        this.$emit("updateProductInfo", data);
        this.base64Image = "";
        this.listFile = [];
        this.inputImage = null;
        this.$refs.modalProductInfo.hide();
      }
    },
    async getProvince() {
      let res = await axios.get(this.apiUrl + "/province");
      let data = this.sortData(res.data);
      this.$set(this, "listProvince", data);
    },
    async getDistrict() {
      let res = await axios.get(
        this.apiUrl + "/district/?idProvince=" + this.selectedProvinceId
      );
      let data = this.sortData(res.data);
      this.$set(this, "listDistrict", data);
    },
    async getCommune() {
      let res = await axios.get(
        this.apiUrl + "/commune/?idDistrict=" + this.selectedDistrictId
      );
      let data = this.sortData(res.data);
      this.$set(this, "listCommune", data);
    },
    sortData(data) {
      const sortedData = data.sort((a, b) => {
        const nameA = a.name;
        const nameB = b.name;

        const regex = /^\D*(\d+)/;

        const matchA = nameA.match(regex);
        const matchB = nameB.match(regex);

        if (matchA && matchB) {
          // If both names have numbers, compare numbers first
          const numA = parseInt(matchA[1]);
          const numB = parseInt(matchB[1]);
          return numA - numB;
        } else if (matchA) {
          // If only A has a number, it should come first
          return -1;
        } else if (matchB) {
          // If only B has a number, it should come first
          return 1;
        } else {
          // If neither has a number, compare alphabetically
          return nameA.localeCompare(nameB);
        }
      });
      return sortedData;
    },
    async getListMember() {
      let res = await requestAsync.get("members");
      if (res.items) {
        res.items.forEach((item) => {
          item.updated_at = moment(item.updated_at).format("DD-MM-YYYY");
        });
        this.optionsMemberSale = res.items
          .sort((a, b) => {
            return new Date(a.created_at) - new Date(b.created_at);
          })
          .filter((item) => item.role === "MEMBER")
          .map((item) => item.name);
      }
    },
  },
  computed: {
    ...mapGetters("customer", ["getMemberInfo", "isAdmin"]),
    listProvinceOptions() {
      let res = this.listProvince.map((item) => {
        return {
          text: item.name,
          value: item.name,
        };
      });
      if (res.length <= 0) return [];
      return res;
    },
    listDistrictOptions() {
      let res = this.listDistrict.map((item) => {
        return {
          text: item.name,
          value: item.name,
        };
      });
      if (res.length <= 0) return [];
      return res;
    },
    listCommuneOptions() {
      let res = this.listCommune.map((item) => {
        return {
          text: item.name,
          value: item.name,
        };
      });
      if (res.length <= 0) return [];
      return res;
    },
  },
  created() {
    this.getProvince();
    this.getListMember();
  },
};
</script>

<style lang="scss">
@import "@/assets/variable.scss";
#modal-add-product {
  .modal-dialog {
    max-width: 800px !important;
  }
  // modal
  .modal-body {
    padding: 0 !important;
  }
  .add-product-modal {
    .header-add-user {
      text-align: center;
      background: rgba($primary-color, 0.65);
      color: white;
      padding: 15px 0px;
      font-size: 18px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    .body-add-user {
      padding: 15px 30px;
      .left-body-add-product {
      }
      .right-body-add-product {
        margin-left: 30px;
        img {
          width: 400px;
          height: 300px;
        }
        .upload-img {
          height: unset !important;
        }
      }
      label {
        color: $primary-color;
      }
      input,
      select {
        width: 300px;
      }
      .product-info-item {
        margin-top: 10px;
      }
    }
  }
  .delete-btn {
    background: $primary-color;
  }
  .custom-select {
    width: 203px;
    padding: 7px 5px;
  }
  .file-name {
    max-width: 400px;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
}
</style>
