<template>
  <div id="member-comp" class="w-100">
    <div class="member-comp">
      <h2>DANH SÁCH NHÂN VIÊN KINH DOẠNH</h2>
      <div class="body-content">
        <div class="d-flex justify-content-end">
          <button class="common-btn mx-2" @click="openEditMemberInfoModal()">
            Thêm nhân viên
            <img :src="require('@/assets/images/user_add.png')" />
          </button>
        </div>
        <div class="table-data">
          <div class="header-table d-flex flex-row justify-content-between">
            <div class="header-item-data no-item">STT</div>
            <div class="header-item-data member-name">Tên</div>
            <div class="header-item-data email">Email</div>
            <div class="header-item-data phone-number">Số điện thoại</div>
            <div class="header-item-data role">Chức vụ</div>
            <div class="header-item-data updated-at">Ngày cập nhập</div>
            <div class="header-item-data action-btns"></div>
          </div>
          <div class="body-table d-flex flex-column">
            <div
              v-for="(member, idx) in members"
              class="record-table d-flex flex-row justify-content-between"
              :key="idx"
            >
              <div
                class="body-item-data no-item"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ Number(idx) + 1 }}
              </div>
              <div
                class="body-item-data member-name"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ member.name }}
              </div>
              <div
                class="body-item-data email"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ member.username }}
              </div>
              <div
                class="body-item-data phone-number"
                @click="openProductDetailPage(member)"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                <b>{{ member.phone }}</b>
              </div>
              <div
                class="body-item-data role"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ getMemberRoles(member.role) }}
              </div>
              <div
                class="body-item-data updated-at"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ member.updated_at }}
              </div>
              <div
                class="body-item-data action-btns d-flex justify-content-evenly"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                <button
                  class="common-btn"
                  @click="openEditMemberInfoModal(member)"
                >
                  Sửa
                </button>
                <button class="delete-btn" @click="deleteMember(member)">
                  Xóa
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MemberInfoModal
      ref="memberInfoModal"
      :member-info-raw="selectedMember"
      @update-member-info="getMemberInfo"
      :from-component="'member'"
    />
  </div>
</template>
<script>
import MemberInfoModal from "./common/MemberInfoModal.vue";
import { requestAsync } from "@/requester/requestAsync";
import moment from "moment";
export default {
  name: "MemberComp",
  components: {
    MemberInfoModal,
  },
  data() {
    return {
      members: [],
      selectedMember: null,
    };
  },
  methods: {
    openAddMemberDialog() {
      alert("Open Add Member Dialog");
    },
    openEditMemberInfoModal(member) {
      this.selectedMember = member;
      this.$refs.memberInfoModal.$refs.memberInnerModal.show();
    },
    getMemberRoles(role) {
      switch (role) {
        case "ADMIN":
          return "Quản trị viên";
        case "MEMBER":
          return "NV kinh doanh";
      }
    },
    async getMemberInfo() {
      // to do
      let res = await requestAsync.get("members");
      if (res.items) {
        res.items.forEach((item) => {
          item.updated_at = moment(item.updated_at).format("DD-MM-YYYY");
        });
        this.members = [...res.items];
        this.members = this.members.sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        });
      }
    },
    async deleteMember(member) {
      let res = await requestAsync.del("members/" + member.id);
      if (!res) {
        this.getMemberInfo();
        this.$toast.open({
          message: "Xoá nhân viên thành công!",
          type: "success",
          position: "top",
          duration: 2000,
        });
      } else {
        this.$toast.open({
          message: "Xoá nhân viên thất bại, vui lòng thử lại sau!",
          type: "error",
          position: "top",
          duration: 2000,
        });
      }
    },
  },
  async created() {
    await this.getMemberInfo();
  },
};
</script>
<style lang="scss">
@import "@/assets/variable.scss";
#member-comp {
  h2 {
    color: $primary-color !important;
  }
  .member-comp {
    padding: 15px 15px 20px;
  }
  .body-content {
    width: 100%;
    max-width: 1150px;
    margin: 0px auto;
    // table
    .table-data {
      margin-top: 15px;
      word-wrap: break-word;
      .header-table {
        background: rgba($primary-color, 0.65);
        color: #fff;
        font-size: 16px;
        border-left: solid 1px $border-color;
        border-right: unset;
        .header-item-data {
          text-align: center;
          padding: 15px 0px;
          border: solid 1px $border-color;
          border-left: unset;
        }
      }
      .body-table {
        width: 100%; //dont know
        max-height: calc(100vh - 325px);
        .record-table {
          border-left: solid 1px $border-color;
        }
        .bg-even-record {
          background: #fff;
        }
        .bg-odd-record {
          background: rgba($primary-color, 0.15);
        }
        .body-item-data {
          padding: 12px 5px;
          border: solid 1px $border-color;
          border-left: unset;
          border-top: unset;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .no-item {
        width: 50px;
        flex: 0 0 50px;
      }
      .member-name {
        width: 250px;
        flex: 0 0 250px;
      }
      .phone-number {
        width: 150px;
        flex: 0 0 150px;
      }
      .email {
        width: 250px;
        flex: 0 0 250px;
      }
      .role {
        width: 150px;
        flex: 0 0 150px;
      }
      .updated-at {
        width: 150px;
        flex: 0 0 150px;
      }
      .action-btns {
        width: 150px;
        flex: 0 0 150px;
      }
    }
  }
}
</style>
