<template>
  <div id="login-page">
    <img class="img-logo" alt="logo image" src="@/assets/images/logo.png" />
    <h2 class="mt-3 fw-bold">{{ title }}</h2>
    <template v-if="!ui.sendPasswordSuccess">
      <form class="mt-3" @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="email" class="form-label">Vui lòng nhập Email</label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="emailInput"
            required
          />
        </div>
        <template v-if="errorMsg.length > 0">
          <div class="msg-err">{{ errorMsg }}</div>
        </template>
        <div class="submit-btn">
          <button
            type="submit"
            class="common-btn-submit btn btn-primary mt-3 py-2 px-4"
          >
            Gửi
          </button>
          <a
            href=""
            @click="$router.push('/login')"
            class="forgot-password mt-3"
            >Quay lại đăng nhập</a
          >
        </div>
      </form>
    </template>
    <template v-else>
      <div class="complete-send-mail">
        <span>
          Mật khẩu đã được gửi đến email của bạn, vui lòng kiểm tra email!
        </span>
        <button
          type="submit"
          class="common-btn-submit btn btn-primary mt-3 py-2 px-4"
          @click="$router.push('/login')"
        >
          Quay lại trang đăng nhập
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { requestAsync } from "@/requester/requestAsync";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      emailInput: "",
      passwordInput: "",
      errorMsg: "",
      ui: {
        sendPasswordSuccess: false,
      },
    };
  },
  methods: {
    async submitForm() {
      this.errorMsg = "";
      let reqData = {
        username: this.emailInput,
      };
      let res = await requestAsync.post("auth/forgot-password", reqData); // todo api send password
      if (!res?.error) {
        this.ui.sendPasswordSuccess = true;
      } else {
        this.errorMsg =
          "Có lỗi, vui lòng kiểm tra email hoặc đợi trong giây lát!";
      }
    },
  },
};
</script>

<style lang="scss">
#login-page {
  width: 100%;
  max-width: 500px;
  margin: 0px auto;
  margin-top: 100px;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 10px 10px 40px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  .img-logo {
    max-width: 350px;
    margin: auto;
  }
  .submit-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .complete-send-mail {
    display: flex;
    flex-direction: column;
  }
  .forgot-password {
    margin-left: auto;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
}
</style>
