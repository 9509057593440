import Vue from "vue";
import Vuex from "vuex";
import customer from "./modules/customer";
import product from "./modules/product";
import user from "./modules/user";
import notification from "./modules/notification";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notification,
    user,
    customer,
    product,
  },
});
