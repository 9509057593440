<template>
  <div
    id="header-comp"
    class="d-flex flex-row justify-content-between w-100 px-3 py-2"
  >
    <div class="logo-img">
      <img :src="require('@/assets/images/logo.png')" />
    </div>
    <div
      v-if="isAdmin"
      class="user-logo d-flex flex-col align-items-center"
      @click="gotoNotificationPage"
    >
      <i
        class="fa fa-bell"
        :class="{ vibrate: countListNotificationNotSeen > 0 }"
        style="font-size: 24px"
      ></i>
      <div class="count" v-if="countListNotificationNotSeen">
        {{ countListNotificationNotSeen }}
      </div>
    </div>
  </div>
</template>

<script>
import { requestAsync } from "@/requester/requestAsync";
import { mapMutations, mapGetters } from "vuex";
import moment from "moment";
import EventBus from "@/eventBus";
export default {
  data() {
    return {
      notifications: [],
    };
  },
  computed: {
    ...mapGetters("customer", ["isAdmin"]),
    countListNotificationNotSeen() {
      return this.notifications.filter((item) => {
        return !item.do_read;
      }).length;
    },
  },
  methods: {
    ...mapMutations("notification", ["setNotifications"]),
    async getListNotification() {
      this.notifications = [];
      // Call API to get list of notifications
      let res = await requestAsync.get("notifications");
      if (res?.length > 0) {
        this.notifications = res
          .sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          })
          .map((item) => {
            return {
              id: item.id,
              title: item.title,
              body: item.body,
              created_at: moment(item.created_at).format("HH:mm DD-MM-YYYY"),
              do_read: item.do_read,
            };
          });
      }
      this.setNotifications(this.notifications);
    },
    gotoNotificationPage() {
      if (this.$router.currentRoute.path === "/notifications") return;
      this.$router.push("/notifications");
    },
  },
  created() {
    this.getListNotification();
    EventBus.$on("update-list-notification", () => {
      this.getListNotification();
    });
  },
  beforeDestroy() {
    EventBus.$on("update-list-notification", () => {
      this.getListNotification();
    });
  },
};
</script>

<style lang="scss">
#header-comp {
  background: #e2e2e2;
  .user-logo {
    margin-right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    .vibrate {
      animation: vibrate 0.3s linear infinite;
    }
    .count {
      position: absolute;
      top: 22px;
      right: -10px;
      color: white;
      font-size: 12px;
      font-weight: bolder;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #eb1e27;
      text-align: center;
    }

    @keyframes vibrate {
      0% {
        transform: translate(0);
      }
      20% {
        transform: translate(-1px, 1px);
      }
      40% {
        transform: translate(-1px, -1px);
      }
      60% {
        transform: translate(1px, 1px);
      }
      80% {
        transform: translate(1px, -1px);
      }
      100% {
        transform: translate(0);
      }
    }
  }
}
.logo-img {
  img {
    max-height: 45px;
  }
}
</style>
