<template>
  <div id="dashboard-comp">
    <div class="list-page">
      <div
        class="user-management-page item-page"
        @click="redirectTo(USER_MANAGEMENT.url)"
        :class="{ 'active-item-page': isMatchCurrentUrl(USER_MANAGEMENT.url) }"
      >
        <i class="fa fa-users icon-dashboard"></i>
      </div>
      <div
        class="building-management-page item-page"
        @click="redirectTo(BUILDING_MANAGEMENT.url)"
        :class="{
          'active-item-page': isMatchCurrentUrl(BUILDING_MANAGEMENT.url),
        }"
      >
        <i class="fa fa-institution icon-dashboard"></i>
      </div>
      <div
        class="building-management-page item-page"
        @click="redirectTo(GROUP_MANAGEMENT.url)"
        :class="{
          'active-item-page': isMatchCurrentUrl(GROUP_MANAGEMENT.url),
        }"
      >
        <i class="fa fa-folder-open-o icon-dashboard"></i>
      </div>
      <div
        v-if="this.isAdmin"
        class="member-management-page item-page"
        @click="redirectTo(MEMBER_MANAGEMENT.url)"
        :class="{
          'active-item-page': isMatchCurrentUrl(MEMBER_MANAGEMENT.url),
        }"
      >
        <i class="fa fa-address-book icon-dashboard"></i>
      </div>
      <div
        v-if="this.isAdmin"
        class="member-management-page item-page"
        @click="redirectTo(NOTIFICATION_MANAGEMENT.url)"
        :class="{
          'active-item-page': isMatchCurrentUrl(NOTIFICATION_MANAGEMENT.url),
        }"
      >
        <i class="fa fa-calendar-o icon-dashboard"></i>
      </div>
      <div
        class="personal-management-page item-page"
        @click="redirectTo(PERSONAL_INFO.url)"
        :class="{
          'active-item-page': isMatchCurrentUrl(PERSONAL_INFO.url),
        }"
      >
        <i class="fa fa-drivers-license-o icon-dashboard"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      USER_MANAGEMENT: {
        url: "/user-management",
      },
      BUILDING_MANAGEMENT: {
        url: "/building-management",
      },
      GROUP_MANAGEMENT: {
        url: "/group-management",
      },
      MEMBER_MANAGEMENT: {
        url: "/members",
      },
      NOTIFICATION_MANAGEMENT: {
        url: "/notifications",
      },
      PERSONAL_INFO: {
        url: "/personal-info",
      },
    };
  },
  computed: {
    ...mapGetters("customer", ["isAdmin"]),
  },
  methods: {
    isMatchCurrentUrl(url) {
      return url === this.$route.path;
    },
    redirectTo(url) {
      if (this.isMatchCurrentUrl(url)) return;
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
#dashboard-comp {
  background: #fff;
  min-width: 56px;
}
.item-page {
  padding: 10px;
  border-bottom: solid 2px #e2e2e2;
  cursor: pointer;
  text-align: center;
  img {
    max-width: 32px;
  }
}
.active-item-page {
  background: #ffbebe;
  .icon-dashboard {
    color: red !important;
  }
}
.icon-dashboard {
  font-size: 32px;
  max-width: 32px;
}
</style>
