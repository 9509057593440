const namespaced = true;

const state = {
  filterObj: {
    name: "",
    company_name: "",
    phone: "",
    email: "",
    statuses: [
      "NEW",
      "INACCESSIBLE",
      "QUOTE",
      "WATCHED",
      "NEGOTIATION",
      "PENDING",
    ],
    pagination: {
      limit: 8,
      order: "desc",
      page: 1,
    },
  },
};
const getters = {
  getFilterObj(state) {
    let localState = window.localStorage.getItem("crm-user");
    if (localState) {
      state.filterObj = JSON.parse(localState).filterObj;
    }
    return state.filterObj;
  },
};
const mutations = {
  setFilterObj(state, filterObj) {
    state.filterObj = filterObj;
    let saveState = JSON.stringify(state);
    window.localStorage.setItem("crm-user", saveState);
    return state.filterObj;
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
};
