<template>
  <div id="personal-info-comp" class="w-100">
    <div class="personal-info-comp">
      <h2>THÔNG TIN CÁ NHÂN</h2>
      <div class="body-content">
        <div class="left-content">
          <div class="account-info">
            <div class="info-item">
              <label>Tên:</label>
              <span>{{ account.name }}</span>
            </div>
            <div class="info-item">
              <label>Số điện thoại:</label>
              <span>{{ account.phone }}</span>
            </div>
            <div class="info-item">
              <label>Email:</label>
              <span>{{ account.username }}</span>
            </div>
            <div class="info-item">
              <label>Chức vụ:</label>
              <span>{{ getRoleName(account.role) }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button
              class="common-btn mx-2"
              @click="openEditPersonalInfoModal()"
            >
              Chỉnh sửa
              <img :src="require('@/assets/images/user_add.png')" />
            </button>
          </div>
        </div>
        <div class="right-content">
          <div class="avatar">
            <button class="common-btn delete-btn" @click="onClickLogOut()">
              Đăng xuất
            </button>
          </div>
        </div>
      </div>
    </div>
    <MemberInfoModal
      ref="memberInfoModal"
      :member-info-raw="account"
      @update-member-info="getMemberInfoAPI"
      :from-component="'personal'"
    />
  </div>
</template>

<script>
import MemberInfoModal from "./common/MemberInfoModal.vue";
import { requestAsync } from "@/requester/requestAsync";
// import moment from "moment";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "PersonalInfoComp",
  components: {
    MemberInfoModal,
  },
  data() {
    return {
      // Add your component data here
      account: {},
    };
  },
  methods: {
    ...mapMutations("customer", ["setMemberInfo"]),
    getRoleName(role) {
      switch (role) {
        case "ADMIN":
          return "Quản trị viên";
        case "MEMBER":
          return "NV kinh doanh";
        default:
          return "Khách hàng";
      }
    },
    // Add your component methods here
    openEditPersonalInfoModal() {
      this.$refs.memberInfoModal.$refs.memberInnerModal.show();
    },
    onClickLogOut() {
      this.$router.push("/login");
    },
    async getMemberInfoAPI() {
      // to do
      let res = await requestAsync.get("members/" + this.account.id);
      if (res) {
        this.account = { ...res };
        this.setMemberInfo(res);
      }
    },
  },
  computed: {
    // Add your computed properties here
    ...mapGetters("customer", ["getMemberInfo"]),
  },
  created() {
    this.account = { ...this.getMemberInfo };
  },
};
</script>

<style lang="scss">
@import "@/assets/variable.scss";
.personal-info-comp {
  padding: 15px 15px 20px;
  h2 {
    color: $primary-color !important;
  }
  .body-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
  .left-content {
    width: 50%;
    min-width: 600px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }
  .right-content {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: center;
    .delete-btn {
      padding: 12px 18px;
      font-size: 24px;
      height: auto;
    }
  }

  .account-info {
    flex-grow: 1;
  }

  .info-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;

    label {
      font-weight: bold;
    }
  }
}
</style>
