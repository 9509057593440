<template>
  <div id="members-page" class="w-100">
    <MemberComp></MemberComp>
  </div>
</template>

<script>
import MemberComp from "@/components/MemberComp.vue";
export default {
  components: { MemberComp },
};
</script>

<style></style>
