<template>
  <b-modal
    hide-header
    hide-footer
    id="modal-member-info"
    ref="memberInnerModal"
  >
    <div class="member-modal">
      <div class="header-member">
        <span>THÔNG TIN NHÂN VIÊN</span>
      </div>
      <div class="body-member">
        <div class="row-member mt-2">
          <div class="member-item">
            <label for="member_name" class="form-label">Tên nhân viên</label>
            <input
              type="input"
              class="form-control"
              id="member_name"
              v-model="memberInfo.name"
              required
            />
          </div>
          <div class="member-item">
            <label for="phone" class="form-label">Số điện thoại</label>
            <input
              type="input"
              class="form-control"
              id="phone"
              v-model="memberInfo.phone"
              required
            />
          </div>
          <div class="member-item">
            <label for="email" class="form-label">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              :disabled="updateType === 'update'"
              v-model="memberInfo.username"
              required
            />
          </div>
          <div class="member-item">
            <label for="floor-area-from" class="form-label">Chức vụ</label>
            <b-form-select
              :disabled="!canChangeRole"
              v-model="memberInfo.role"
              :options="listRole"
            ></b-form-select>
          </div>
        </div>
        <div class="mt-4">
          <div class="button-submit d-flex flex-row justify-content-center">
            <button
              @click="() => $refs.memberInnerModal.hide()"
              class="clear-btn mx-auto"
            >
              Hủy
            </button>
            <button @click="submitUserInfo()" class="common-btn mx-auto">
              {{ title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { requestAsync } from "@/requester/requestAsync";
export default {
  name: "MemberInfoModal",
  data() {
    return {
      listRole: [
        { value: "ADMIN", text: "Admin" },
        { value: "MEMBER", text: "Nhân viên kinh doanh" },
      ],
      memberInfo: {
        name: "",
        phone: "",
        username: "",
        role: "MEMBER",
        member_create_customer_at: "",
      },
      updateType: "create",
      canChangeRole: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "Lưu",
    },
    memberInfoRaw: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fromComponent: {
      type: String,
      default: "",
      validator: function (value) {
        return ["member", "personal"].includes(value);
      },
    },
  },
  watch: {
    memberInfoRaw: {
      handler(val) {
        !val?.id ? (this.updateType = "create") : (this.updateType = "update");
        this.memberInfo = { ...val };
      },
      deep: true,
      immediate: true,
    },
    fromComponent: {
      handler(val) {
        this.canChangeRole = val === "member";
      },
      immediate: true,
    },
  },
  methods: {
    async submitUserInfo() {
      const CREATE_MEMBER_URL = "members";
      const UPDATE_MEMBER_URL = "members/" + this.memberInfo.id;
      let res;
      if (this.updateType === "create") {
        res = await requestAsync.post(CREATE_MEMBER_URL, this.memberInfo);
      } else {
        res = await requestAsync.put(UPDATE_MEMBER_URL, this.memberInfo);
      }
      if (!res.message) {
        this.$emit("update-member-info");
        this.$refs.memberInnerModal.hide();
        this.updateType === "create"
          ? this.showToast("Thêm nhân viên thành công", "success")
          : this.showToast("Cập nhật thông tin thành công", "success");
      } else {
        this.showToast("Có lỗi xảy ra, vui lòng thử lại sau", "error");
      }
      this.$refs.memberInnerModal.hide();
    },
    showToast(msg, type) {
      this.$toast.open({
        message: msg,
        type: type,
        position: "top",
        duration: 2000,
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/variable.scss";
// modal
.modal-body {
  padding: 0 !important;
}
.member-modal {
  .header-member {
    text-align: center;
    background: rgba($primary-color, 0.65);
    color: white;
    padding: 15px 0px;
    font-size: 18px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .body-member {
    padding: 15px;
    label {
      color: $primary-color;
    }
    .row-member {
      display: flex;
      flex-direction: column;
      .w-30 {
        width: 30%;
      }
    }
    .custom-select {
      padding: 5px;
      width: 100%;
    }
  }
}
</style>
