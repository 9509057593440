<template>
  <div class="login page">
    <forgetPassword title="Quên mật khẩu"></forgetPassword>
  </div>
</template>
<script>
import ForgetPassword from "@/components/ForgetPasswordComp.vue";
export default {
  components: {
    forgetPassword: ForgetPassword,
  },
};
</script>
