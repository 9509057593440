<template>
  <b-modal
    hide-header
    hide-footer
    id="modal-save-group-product"
    ref="saveGroupProductModal"
  >
    <div class="save-group-product-modal">
      <div class="header-save-product">
        <span>LƯU NHÓM</span>
      </div>
      <hr />
      <div class="body-save-product">
        <div class="column">
          <button
            class="common-btn select-btn"
            @click="
              () => {
                showCreateNew = false;
                showEdit = true;
              }
            "
          >
            Vào nhóm có sẵn
          </button>
          <b-form-select
            v-if="showEdit"
            v-model="selectedGroup"
            :options="groupOptions"
          ></b-form-select>
        </div>
        <div class="or">OR</div>
        <div class="column">
          <button
            class="common-btn select-btn"
            @click="
              () => {
                showCreateNew = true;
                showEdit = false;
              }
            "
          >
            Tạo mới
          </button>
          <div v-if="showCreateNew">
            <b-form-input
              class="mt-2"
              v-model="newGroupName"
              placeholder="Nhập tên group mới"
            ></b-form-input>
          </div>
        </div>
      </div>
      <hr />
      <div class="footer-save-product">
        <button class="common-btn" @click="saveGroup">Lưu</button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { requestAsync } from "@/requester/requestAsync";

export default {
  props: {
    listMultiSelect: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showCreateNew: false,
      showEdit: false,
      selectedGroup: null,
      newGroupName: "",
      groupOptions: [],
    };
  },
  methods: {
    async saveGroup() {
      let product_ids = this.listMultiSelect.map((product) => product.id);
      let res;
      if (this.showCreateNew) {
        res = await requestAsync.post("groups", {
          name: this.newGroupName,
          product_ids: product_ids,
        });
      } else {
        res = await requestAsync.post(
          "groups/" + this.selectedGroup + "/add-products",
          {
            product_ids: product_ids,
          }
        );
      }
      if (res && res.id) {
        this.$toast.open({
          message: "Lưu thông tin thành công!",
          type: "success",
          position: "top",
          duration: 2000,
        });
        this.$emit("saveGroupSuccess");
      } else {
        this.$toast.open({
          message: "Lưu thông tin thất bại, vui lòng thử lại sau!",
          type: "error",
          position: "top",
          duration: 2000,
        });
      }
      this.getAllGroup();
      this.$refs.saveGroupProductModal.hide();
    },
    async getAllGroup() {
      this.groupOptions = [];
      let res = await requestAsync.get("groups");
      if (res && res.items.length > 0) {
        console.log(res.items);
        this.groupOptions = res.items.map((group) => {
          return {
            value: group.id,
            text: group.name,
          };
        });
        this.selectedGroup = this.groupOptions[0].value;
      } else {
        this.groupOptions = [
          {
            value: 0,
            text: "Chưa có nhóm nào",
            disable: true,
          },
        ];
      }
    },
  },
  async created() {
    await this.getAllGroup();
  },
};
</script>
<style lang="scss">
@import "@/assets/variable.scss";
@import "@/assets/common-class.scss";
.save-group-product-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1rem;

  .header-save-product {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }

  .body-save-product {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    .select-btn {
      background: green;
    }
    .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 10px;
      .label {
        font-size: 20px;
      }
    }
    .custom-select {
      margin-top: 10px;
      padding: 3px 5px !important;
    }
    .or {
      margin: 20px 0;
    }
  }

  .footer-save-product {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
</style>
