<template>
  <div id="product-management">
    <div class="detail-management">
      <h2>DANH SÁCH SẢN PHẨM</h2>
      <div class="list-action-btn d-flex flex-row justify-content-between">
        <div class="search position-relative">
          <input
            type="text"
            placeholder="Tìm kiếm theo tên, số điện thoại, email"
            class="form-control search-form"
            v-model="searchKeyword"
            @keydown.enter="triggerSearch()"
          />
          <img
            @click="searchProductByKeyWord()"
            class="img-absolute"
            :src="require('@/assets/images/tabler_search.png')"
          />
        </div>
        <div class="list-button">
          <button class="common-btn mx-2" @click="openDialogMultiSelect()">
            Lưu nhóm
            <img
              class="group-btn"
              :src="require('@/assets/images/group.png')"
            />
          </button>
          <button
            class="common-btn mx-2"
            data-toggle="collapse"
            data-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Bộ lọc
            <img :src="require('@/assets/images/filter.png')" />
          </button>
          <button class="common-btn mx-2" @click="refreshDataProductInfo()">
            Cập nhập
            <img
              :class="{ rotate: isLoadingProductInfo }"
              :src="require('@/assets/images/refresh.png')"
            />
          </button>
          <button class="common-btn mx-2" v-b-modal.modal-add-product>
            Thêm SP
            <img :src="require('@/assets/images/user_add.png')" />
          </button>
        </div>
      </div>
      <div class="collapse mt-2" id="collapseExample">
        <div class="card card-body">
          <div class="filter-div">
            <div class="header-filter">BỘ LỌC</div>
            <div class="body-filter">
              <div class="first-row d-flex flex-wrap">
                <div class="left-fields">
                  <div class="w-45">
                    <label for="floor-area-from" class="form-label"
                      >Diện tích sàn (số) từ</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="floor-area-from"
                      v-model="filterObject.floor_area.min"
                      required
                    />
                  </div>
                  <div class="w-45">
                    <label for="floor-area-to" class="form-label">Đến</label>
                    <input
                      type="text"
                      class="form-control"
                      id="floor-area-to"
                      v-model="filterObject.floor_area.max"
                      required
                    />
                  </div>
                </div>
                <div class="right-fields">
                  <div class="filter-item">
                    <label for="product-name" class="form-label"
                      >Tên sản phẩm</label
                    >
                    <input
                      type="input"
                      class="form-control"
                      id="product-name"
                      v-model="filterObject.name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="first-row d-flex flex-wrap">
                <div class="left-fields">
                  <div class="w-45">
                    <label for="floor-area-from" class="form-label"
                      >Diện tích thuê (số) từ</label
                    >
                    <input
                      type="input"
                      class="form-control"
                      id="floor-area-from"
                      v-model="filterObject.empty_area_range.min"
                      required
                    />
                  </div>
                  <div class="w-45">
                    <label for="floor-area-to" class="form-label">Đến</label>
                    <input
                      type="input"
                      class="form-control"
                      id="floor-area-to"
                      v-model="filterObject.empty_area_range.max"
                      required
                    />
                  </div>
                </div>
                <div class="right-fields">
                  <div class="w-45">
                    <label for="floor-area-from" class="form-label"
                      >Tên quản lý</label
                    >
                    <input
                      type="input"
                      class="form-control"
                      id="floor-area-from"
                      v-model="filterObject.manager_name"
                      required
                    />
                  </div>
                  <div class="w-45">
                    <label for="floor-area-to" class="form-label"
                      >Điện thoại quản lý</label
                    >
                    <input
                      type="input"
                      class="form-control"
                      id="floor-area-to"
                      v-model="filterObject.manager_phone"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="first-row d-flex flex-wrap">
                <div class="left-fields">
                  <div class="w-45">
                    <label for="floor-area-from" class="form-label"
                      >Giá thuê (số) từ</label
                    >
                    <input
                      type="input"
                      class="form-control"
                      id="floor-area-from"
                      v-model="filterObject.price_range.min"
                      required
                    />
                  </div>
                  <div class="w-45">
                    <label for="floor-area-to" class="form-label">Đến</label>
                    <input
                      type="input"
                      class="form-control"
                      id="floor-area-to"
                      v-model="filterObject.price_range.max"
                      required
                    />
                  </div>
                </div>
                <div class="right-fields flex-start">
                  <div class="w-45 d-flex flex-column">
                    <label for="floor-area-from" class="form-label"
                      >Tỉnh (TP)</label
                    >
                    <b-form-select
                      v-model="filterObject.address.province"
                      :options="listProvinceOptions"
                    ></b-form-select>
                  </div>
                  <div class="w-45 d-flex flex-column">
                    <label for="floor-area-from" class="form-label">Quận</label>
                    <b-form-select
                      v-model="filterObject.address.district"
                      :options="listDistrictOptions"
                    ></b-form-select>
                  </div>
                </div>
              </div>
              <div class="first-row d-flex flex-wrap">
                <div class="left-fields">
                  <div class="filter-item d-flex flex-column">
                    <label for="floor-area" class="form-label"
                      >Người phụ trách</label
                    >
                    <b-form-select
                      v-model="filterObject.saler_name"
                      :options="optionListSale"
                    ></b-form-select>
                  </div>
                </div>
                <div class="right-fields confirm-btn-field">
                  <div class="w-45 d-flex flex-column">
                    <label for="floor-area-to" class="form-label">Phường</label>
                    <b-form-select
                      v-model="filterObject.address.ward"
                      :options="listCommuneOptions"
                    ></b-form-select>
                  </div>
                  <div class="w-45">
                    <label for="floor-area-to" class="form-label">Số nhà</label>
                    <input
                      type="input"
                      class="form-control"
                      id="floor-area-to"
                      v-model="filterObject.address.detail"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="first-row d-flex flex-wrap">
                <div class="list-option-checkbox">
                  <label for="checkbox-group-1" class="form-label">Hướng</label>
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    class="list-checkbox"
                    v-model="filterObject.building_directions"
                    :options="listDirectionOptions"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </div>
                <div class="list-option-checkbox">
                  <label for="checkbox-group-1" class="form-label">Rank</label>
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    class="list-checkbox"
                    v-model="filterObject.ranks"
                    :options="listRankOptions"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </div>
                <div class="filter-item-submit button_submit">
                  <button
                    class="common-btn mx-2"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    @click="getListProductFilter()"
                  >
                    Xác nhận
                  </button>
                  <button
                    class="clear-btn mx-2"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    @click="clearFilter()"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-data">
        <div class="header-table d-flex flex-row justify-content-between">
          <div class="header-item-data multi-select">Chọn</div>
          <div class="header-item-data no-item">STT</div>
          <div class="header-item-data created_date">Ngày cập nhập</div>
          <div class="header-item-data prod-name">Tên sản phẩm</div>
          <div class="header-item-data address">Địa chỉ</div>
          <div class="header-item-data avairable-area">
            Diện tích trống (mô tả)
          </div>
          <div class="header-item-data price">Giá thuê (Số) ($/m2)</div>
          <div class="header-item-data manage-price">Phí quản lý</div>
          <div class="header-item-data manage">Tên quản lý</div>
          <div class="header-item-data manage-phone">SĐT quản lý</div>
          <div class="header-item-data brokerage-fee">Phí M.G</div>
          <div class="header-item-data num-motorbike">Xe máy</div>
          <div class="header-item-data num-car">Ô tô</div>
          <div class="header-item-data over-time">Ngoài giờ</div>
          <div class="header-item-data structure">Kết cấu & thang máy</div>
          <div class="header-item-data floor-area">Diện tích sàn (Số)</div>
          <div class="header-item-data tall">Cao trần</div>
          <div class="header-item-data direction">Hướng</div>
        </div>
        <template v-if="isLoadingData">
          <vue-loading
            :active.sync="isLoadingData"
            :is-full-page="fullPage"
            style="display: unset"
          ></vue-loading>
        </template>
        <template v-else>
          <div class="body-table d-flex flex-column">
            <div
              v-for="(record, idx) in listProductInfo"
              @contextmenu.prevent="openNewTab($event, record)"
              class="record-table d-flex flex-row justify-content-between"
              :key="idx"
            >
              <div
                class="body-item-data multi-select"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                <b-form-checkbox
                  v-model="listMultiSelect"
                  :value="record"
                ></b-form-checkbox>
              </div>
              <div
                class="body-item-data no-item"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ idx + 1 }}
              </div>
              <div
                class="body-item-data created_date"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record.updated_at }}
              </div>
              <div
                class="body-item-data prod-name"
                @click="openProductDetailPage(record)"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                <b>{{ record.name }}</b>
              </div>
              <div
                class="body-item-data address"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record?.address?.detail
                }}<template v-if="record?.address?.ward">, </template>
                {{ record?.address?.ward
                }}<template v-if="record?.address?.district">, </template>
                {{ record?.address?.district
                }}<template v-if="record?.address?.province">, </template>
                {{ record?.address?.province }}
              </div>
              <div
                class="body-item-data avairable-area"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record?.product_detail?.empty_area_detail }}
              </div>
              <div
                class="body-item-data price"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record?.rent_detail?.basic_rent_cost }}
              </div>
              <div
                class="body-item-data manage-price"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record?.rent_detail?.service_cost }}
              </div>
              <div
                class="body-item-data manage"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record.manager_name }}
              </div>
              <div
                class="body-item-data manage-phone"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record.manager_phone }}
              </div>
              <div
                class="body-item-data brokerage-fee"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record?.rent_detail?.brokerage_fee }}
              </div>
              <div
                class="body-item-data num-motorbike"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record?.rent_detail?.motor_park_cost }}
              </div>
              <div
                class="body-item-data num-car"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record?.rent_detail?.car_park_cost }}
              </div>
              <div
                class="body-item-data over-time"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record?.rent_detail?.over_time_cost }}
              </div>
              <div
                class="body-item-data structure"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record?.product_detail?.structure }}
              </div>
              <div
                class="body-item-data floor-area"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record?.product_detail?.floor_area }}
              </div>
              <div
                class="body-item-data tall"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record?.product_detail?.celling_height }}
              </div>
              <div
                class="body-item-data direction"
                :class="{
                  'bg-even-record': idx % 2 != 0,
                  'bg-odd-record': idx % 2 == 0,
                }"
              >
                {{ record?.product_detail?.building_direction }}
              </div>
            </div>
          </div>
        </template>
      </div>
      <pagination
        class="mt-3"
        v-model="page"
        :per-page="perPage"
        :records="Number(totalPage) * perPage"
        @paginate="myCallback"
      />
      <productInfoModal
        :productInfo="newProductInfo"
        @updateProductInfo="updateProductInfo"
        title="Tạo sản phẩm"
      >
      </productInfoModal>
      <saveGroupProductModal
        ref="saveGroupProduct"
        :listMultiSelect="listMultiSelect"
        @saveGroupSuccess="saveGroupSuccess"
      />
    </div>
  </div>
</template>

<script>
import listSale from "@/ultils/listSale";
import productInfoModal from "@/components/common/ProductInfoModal.vue";
import saveGroupProductModal from "@/components/common/SaveGroupProduct.vue";
import { requestAsync } from "@/requester/requestAsync";
import moment from "moment";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import listDirectionOptions from "@/ultils/listDirection.js";
import listRankOptions from "@/ultils/listRank.js";
export default {
  data() {
    return {
      listProductInfo: [],
      page: 1,
      perPage: 50,
      isLoadingProductInfo: false,
      searchKeyword: null,
      // for filter
      filterObject: {
        address: {
          detail: "",
          district: "",
          province: "Thành phố Hồ Chí Minh",
          ward: "",
        },
        building_directions: [],
        floor_area: {
          max: "",
          min: "",
        },
        empty_area_range: {
          max: "",
          min: "",
        },
        manager_company: "",
        manager_name: "",
        manager_phone: "",
        name: "",
        saler_name: "",
        price_range: {
          max: "",
          min: "",
        },
        ranks: [],
        pagination: {
          limit: 50,
          order: "desc",
          page: 1,
        },
      },
      checkboxStatusFilter: [],
      STATUS_OPTION: ["SUCCESS", "PENDING", "FAIL"],
      newProductInfo: {},
      newProductId: null,
      totalPage: 0,
      isGetByFilter: false,
      isLoadingData: false,
      fullPage: false,
      //filter
      apiUrl:
        "https://vietnam-administrative-division-json-server-swart.vercel.app",
      listProvince: [],
      listDistrict: [],
      listCommune: [],
      selectedProvinceId: 79,
      selectedDistrictId: null,
      selectedCommuneId: null,
      listDirectionOptions: listDirectionOptions,
      listRankOptions: listRankOptions,
      listMultiSelect: [],
    };
  },
  components: {
    productInfoModal,
    saveGroupProductModal,
  },
  computed: {
    ...mapGetters("product", ["getFilterObj", "filterHasValue"]),
    optionListSale() {
      return listSale.map((item) => {
        return {
          text: item,
          value: item,
        };
      });
    },
    listProvinceOptions() {
      let res = this.listProvince.map((item) => {
        return {
          text: item.name,
          value: item.name,
        };
      });
      if (res.length <= 0) return [];
      return res;
    },
    listDistrictOptions() {
      let res = this.listDistrict.map((item) => {
        return {
          text: item.name,
          value: item.name,
        };
      });
      if (res.length <= 0) return [];
      return res;
    },
    listCommuneOptions() {
      let res = this.listCommune.map((item) => {
        return {
          text: item.name,
          value: item.name,
        };
      });
      if (res.length <= 0) return [];
      return res;
    },
  },
  watch: {
    getFilterObj: {
      deep: true,
      immediate: true,
      handler: function () {
        this.filterObject = JSON.parse(JSON.stringify(this.getFilterObj));
      },
    },
    "filterObject.address.province": {
      deep: true,
      async handler() {
        this.selectedProvinceId = this.listProvince.filter((province) => {
          return province.name === this.filterObject.address.province;
        })[0]?.idProvince;
      },
    },
    selectedProvinceId: {
      immediate: true,
      async handler() {
        if (this.selectedProvinceId) {
          await this.getDistrict();
        }
      },
    },
    "filterObject.address.district": {
      deep: true,
      async handler() {
        this.selectedDistrictId = this.listDistrict.filter((district) => {
          return district.name === this.filterObject.address.district;
        })[0]?.idDistrict;
      },
    },
    listDistrict: {
      deep: true,
      async handler() {
        this.selectedDistrictId = this.listDistrict.filter((district) => {
          return district.name === this.filterObject.address.district;
        })[0]?.idDistrict;
      },
    },
    selectedDistrictId: {
      immediate: true,
      async handler() {
        if (this.selectedDistrictId) {
          await this.getCommune();
        }
      },
    },
  },
  methods: {
    ...mapMutations("product", ["setFilterObj"]),
    initNewProductInfo() {
      let data = {
        address: {
          detail: "",
          district: "",
          province: "Thành phố Hồ Chí Minh",
          ward: "",
        },
        created_at: "",
        deleted_at: 0,
        files: [
          {
            contentType: "",
            created_at: "",
            deleted_at: 0,
            filename: "",
            id: "",
            productID: "",
            updated_at: "",
          },
        ],
        id: "",
        image: {
          contentType: "",
          created_at: "",
          deleted_at: 0,
          filename: "",
          id: "",
          productID: "",
          updated_at: "",
        },
        manager_name: "",
        manager_phone: "",
        saler_name: "",
        name: "",
        note: "",
        product_detail: {
          area: 0,
          building_direction: "",
          celling_height: "",
          completion_time: "",
          empty_area: [0],
          empty_area_detail: "",
          floor_area: 0,
          manager_company: "",
          owner: "",
          structure: "",
        },
        rank: "",
        rent_detail: {
          basic_rent_cost: "",
          brokerage_fee: "",
          car_park_cost: "",
          decor_time: "",
          electronic_cost: "",
          full_rent_cost: "",
          motor_park_cost: "",
          over_time_cost: "",
          rent_area: "",
          rent_deposit: "",
          rent_time: "",
          service_cost: "",
        },
        updated_at: "",
        website: "",
      };
      this.$set(this, "newProductInfo", data);
    },
    openNewTab(event, product) {
      if (event.button === 2) {
        // Check if the right mouse button is clicked
        event.preventDefault(); // Prevent the default context menu
        window.open("/buildling-detail/" + product.id, "_blank");
      }
    },
    processData(data) {
      var clone = { ...data };
      if (clone.empty_area_range.max == 0 && clone.empty_area_range.min == 0) {
        delete clone.empty_area_range;
      }
      if (clone.price_range.max == 0 && clone.price_range.min == 0) {
        delete clone.price_range;
      }
      if (clone.floor_area.max == 0 && clone.floor_area.min == 0) {
        delete clone.floor_area;
      }
      return clone;
    },
    async refreshDataProductInfo() {
      this.isLoadingProductInfo = true;
      await this.getProductInfo();
      this.isLoadingProductInfo = false;
    },
    async submitImageProduct(data) {
      let formData = new FormData();
      formData.append("file", data);

      let res = await requestAsync.post(
        "files/product/image?product_id=" + this.newProductId,
        formData
      );
      console.log(res);
    },
    async submitFileProduct(data) {
      let formData = new FormData();
      Array.from(data).forEach((file) => {
        formData.append("files[]", file);
      });
      let res = await requestAsync.post(
        "files/product/files?product_id=" + this.newProductId,
        formData
      );
      console.log(res);
    },
    async updateProductInfo(data) {
      this.$set(this, "newProductInfo", data?.productInfo);
      let createNewSuccess = await this.createNewProduct();
      if (createNewSuccess) {
        this.submitFileProduct(data.listFile);
        this.submitImageProduct(data.inputImage);
      }
      this.getProductInfo();
    },
    convertStringToArrNumber(data) {
      // change format from string to number:
      if (data && data.length > 1) {
        data = data.split(",").map(Number);
        return data;
      }
      return [0];
    },
    async createNewProduct() {
      let reqData = this.newProductInfo;
      this.newProductId = null;
      reqData.product_detail.empty_area = this.convertStringToArrNumber(
        reqData?.product_detail?.empty_area
      );
      reqData.product_detail.area = String(reqData.product_detail.area);
      reqData.product_detail.floor_area = Number(
        reqData.product_detail.floor_area
      );
      let res = await requestAsync.post("products", reqData);
      if (res?.id) {
        this.$toast.open({
          message: "Tạo sản phẩm mới thành công!",
          type: "success",
          position: "top",
          duration: 2000,
        });
        this.newProductId = res?.id;
        this.initNewProductInfo();
        return true;
      } else {
        this.$toast.open({
          message: "Tạo sản phẩm mới thất bại!",
          type: "error",
          position: "top",
          duration: 2000,
        });
        this.initNewProductInfo();
        return false;
      }
    },
    formatTime() {
      for (let item of this.listProductInfo) {
        item.updated_at = moment(item.updated_at).format("DD-MM-YYYY");
      }
    },
    clearFilter() {
      (this.filterObject = {
        address: {
          detail: "",
          district: "",
          province: "Thành phố Hồ Chí Minh",
          ward: "",
        },
        floor_area: {
          max: "",
          min: "",
        },
        building_directions: [],
        empty_area_range: {
          max: "",
          min: "",
        },
        manager_company: "",
        manager_name: "",
        manager_phone: "",
        name: "",
        saler_name: "",
        price_range: {
          max: "",
          min: "",
        },
        pagination: {
          limit: 50,
          order: "desc",
          page: 1,
        },
        ranks: [],
      }),
        this.setFilterObj(this.filterObject);
      this.getProductInfo();
    },
    async getListProductFilter(page = 1) {
      this.isLoadingData = true;
      this.filterObject.empty_area_range.min = Number(
        this.filterObject.empty_area_range.min
      );
      this.filterObject.empty_area_range.max = Number(
        this.filterObject.empty_area_range.max
      );
      this.filterObject.price_range.min = Number(
        this.filterObject.price_range.min
      );
      this.filterObject.price_range.max = Number(
        this.filterObject.price_range.max
      );
      this.filterObject.floor_area.min = Number(
        this.filterObject.floor_area.min
      );
      this.filterObject.floor_area.max = Number(
        this.filterObject.floor_area.max
      );
      this.page = page;
      this.filterObject.pagination.page = page;

      var clone = this.processData(this.filterObject);
      this.setFilterObj(this.filterObject);
      let res = await requestAsync.post("products/fetch", clone);
      if (res?.items) {
        this.isGetByFilter = true;
        this.totalPage = res.total;
        this.$set(this, "listProductInfo", res.items);
        this.formatTime();
      }
      this.isLoadingData = false;
    },
    async getProductInfo(page = 1) {
      this.isLoadingData = true;
      this.page = page;
      let res = await requestAsync.get(
        `products?limit=` + this.perPage + `&page=` + page + `&order=desc`
      );
      if (res?.items) {
        this.isGetByFilter = false;
        this.totalPage = res.total;
        this.$set(this, "listProductInfo", res.items);
        this.formatTime();
        this.isLoadingData = false;
      }
    },
    triggerSearch() {
      // Use $nextTick to ensure that searchKeyword is updated before calling searchProductByKeyWord
      this.$nextTick(() => {
        this.searchProductByKeyWord();
      });
    },
    async searchProductByKeyWord(page = 1) {
      this.isLoadingData = true;
      this.page = page;
      let res = await requestAsync.get(
        `products/search?keyword=${this.searchKeyword}&page=${page}&limit=50&order=desc`
      );
      if (res?.items) {
        this.isGetByFilter = true;
        this.totalPage = res.total;
        this.$set(this, "listProductInfo", res.items);
        this.formatTime();
      }
      this.isLoadingData = false;
    },
    myCallback: function (page) {
      if (this.isGetByFilter) {
        this.getListProductFilter(page);
      } else {
        this.getProductInfo(page);
      }
    },
    openProductDetailPage(product) {
      this.$router.push("/buildling-detail/" + product.id);
    },
    async getProvince() {
      let res = await axios.get(this.apiUrl + "/province");
      let data = this.sortData(res.data);
      this.$set(this, "listProvince", data);
    },
    async getDistrict() {
      let res = await axios.get(
        this.apiUrl + "/district/?idProvince=" + this.selectedProvinceId
      );
      let data = this.sortData(res.data);
      this.$set(this, "listDistrict", data);
    },
    async getCommune() {
      let res = await axios.get(
        this.apiUrl + "/commune/?idDistrict=" + this.selectedDistrictId
      );
      let data = this.sortData(res.data);
      this.$set(this, "listCommune", data);
    },
    sortData(data) {
      const sortedData = data.sort((a, b) => {
        const nameA = a.name;
        const nameB = b.name;

        const regex = /^\D*(\d+)/;

        const matchA = nameA.match(regex);
        const matchB = nameB.match(regex);

        if (matchA && matchB) {
          // If both names have numbers, compare numbers first
          const numA = parseInt(matchA[1]);
          const numB = parseInt(matchB[1]);
          return numA - numB;
        } else if (matchA) {
          // If only A has a number, it should come first
          return -1;
        } else if (matchB) {
          // If only B has a number, it should come first
          return 1;
        } else {
          // If neither has a number, compare alphabetically
          return nameA.localeCompare(nameB);
        }
      });
      return sortedData;
    },
    openDialogMultiSelect() {
      this.$refs.saveGroupProduct.$refs.saveGroupProductModal.show();
    },
    saveGroupSuccess() {
      console.log("save group success");
      this.listMultiSelect = [];
    },
  },
  mounted() {
    // Select all paragraphs with the class VuePagination__count
    const paragraphs = document.querySelectorAll(".VuePagination__count");
    // Loop through each paragraph and remove it
    paragraphs.forEach((paragraph) => {
      paragraph.remove();
    });
  },
  created() {
    this.initNewProductInfo();
    if (this.filterHasValue) {
      this.getListProductFilter();
    } else {
      this.getProductInfo();
    }
    this.getProvince();
  },
};
</script>

<style lang="scss">
@import "@/assets/variable.scss";
#product-management {
  h2 {
    color: $primary-color !important;
  }
  .detail-management {
    padding: 15px 15px 20px;
  }
  .list-action-btn {
    margin-top: 20px;
    .list-button {
      display: flex;
      flex-direction: row;
    }
  }
  .img-absolute {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
  .search-form {
    width: 50%;
    min-width: 400px;
    height: 100%;
  }
  .group-btn {
    width: 25px;
    height: 25px;
  }
  // filter
  .filter-div {
    .header-filter {
      background: rgba($primary-color, 0.6);
      text-align: center;
      padding: 10px 0px;
      color: #fff;
      font-size: 18px;
    }
    .body-filter {
      margin-top: 20px;
      .first-row {
        margin-top: 20px;
      }
      .left-fields {
        width: 40%;
        padding: 0px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .right-fields {
        width: 60%;
        padding: 0px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .filter-item {
        flex-grow: 1;
      }
      .w-45 {
        width: 45% !important;
      }
      .w-30 {
        width: 30% !important;
      }
      .margin-5 {
        margin: 0 5%;
      }
      .margin-left-5 {
        margin-left: 5%;
      }
      .custom-select {
        padding: 7px;
      }
      .filter-item-submit {
        width: 30%;
        align-self: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
      .list-option-checkbox {
        width: 30%;
        margin: 0px 20px;
        flex-grow: 1;
        justify-content: space-between;
        flex-wrap: wrap;
        .list-checkbox {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .custom-control {
            min-width: 50%;
          }
          .custom-control-input {
            margin-right: 20px;
          }
        }
      }
    }
  }
  // table data
  .table-data {
    font-size: 12px;
    margin-top: 15px;
    overflow-x: auto;
    text-align: center;
    max-width: 2302px;
    position: relative;
    .header-table {
      font-size: 14px;
      width: 100%; //dont know
      border-left: solid 1px $border-color;
      position: sticky;
      top: 0;
      .header-item-data {
        background: #f27473;
        color: #fff;
        text-align: center;
        padding: 15px 5px;
        border: solid 1px $border-color;
        border-left: unset;
      }
    }
    .body-table {
      width: 100%; //dont know
      max-height: calc(100vh - 325px);
      .record-table {
        border-left: solid 1px $border-color;
      }
      .bg-even-record {
        background: #fff;
      }
      .bg-odd-record {
        background: rgba($primary-color, 0.15);
      }
      .body-item-data {
        padding: 12px 5px;
        text-align: center;
        border: solid 1px $border-color;
        border-left: unset;
        border-top: unset;
      }
    }
    .multi-select {
      width: 50px;
      flex: 0 0 50px;
    }
    .no-item {
      width: 60px;
      flex: 0 0 60px;
    }
    .created_date {
      width: 100px;
      flex: 0 0 100px;
    }
    .prod-name {
      width: 150px;
      flex: 0 0 150px;
      font-size: 13px;
      cursor: pointer;
      &:hover {
        color: $secondary-color;
      }
    }
    .address {
      width: 250px;
      flex: 0 0 250px;
    }
    .avairable-area {
      width: 200px;
      flex: 0 0 200px;
    }
    .price {
      width: 150px;
      flex: 0 0 150px;
    }
    .manage-price {
      width: 120px;
      flex: 0 0 120px;
    }
    .manage {
      width: 150px;
      flex: 0 0 150px;
    }
    .brokerage-fee {
      width: 60px;
      flex: 0 0 60px;
    }
    .manage-phone {
      width: 125px;
      flex: 0 0 125px;
    }
    .num-motorbike {
      width: 120px;
      flex: 0 0 120px;
    }
    .num-car {
      width: 120px;
      flex: 0 0 120px;
    }
    .over-time {
      width: 120px;
      flex: 0 0 120px;
    }
    .structure {
      width: 250px;
      flex: 0 0 250px;
    }
    .floor-area {
      width: 125px;
      flex: 0 0 125px;
      text-align: center;
    }
    .available-area {
      width: 350px;
      flex: 0 0 350px;
    }
    .tall {
      width: 80px;
      flex: 0 0 80px;
    }
    .direction {
      width: 120px;
      flex: 0 0 120px;
    }
  }

  // pagination
  .page-link {
    color: $primary-color !important;
  }
  .disabled > .page-link {
    background: rgba($primary-color, 0.15) !important;
  }
  .active > .page-link,
  .page-link.active {
    background: $primary-color !important;
    color: #fff !important;
    border-color: $primary-color !important;
  }
  // rotate
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .rotate {
    animation: rotate 2s linear infinite; /* Rotate animation lasts for 3 seconds, linear timing, and repeats infinitely */
  }
}
</style>
