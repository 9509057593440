/* eslint-disable no-return-await */
import axios from "axios";
import store from "@/store/index";
import router from "@/router/index";

const createRequester = function (serviceApiUrl, timeoutReq) {
  const client = axios.create({
    baseURL: serviceApiUrl,
    timeout: timeoutReq,
  });

  let requestArray = [];

  const post = async function (path, reqObj, success, error) {
    if (reqObj === null) {
      reqObj = {};
    }
    if (
      requestArray.indexOf(
        "post" + ":" + path + ":" + JSON.stringify(reqObj)
      ) !== -1
    ) {
      return;
    }
    requestArray.push("post" + ":" + path + ":" + JSON.stringify(reqObj));
    return await doRequest("post", path, reqObj, success, error);
  };

  const get = async function (path, reqObj, success, error) {
    if (reqObj === null) {
      reqObj = {};
    }
    if (
      requestArray.indexOf(
        "get" + ":" + path + ":" + JSON.stringify(reqObj)
      ) !== -1
    ) {
      return;
    }
    requestArray.push("get" + ":" + path + ":" + JSON.stringify(reqObj));
    return await doRequest("get", path, reqObj, success, error);
  };

  const put = async function (path, reqObj, success, error) {
    if (reqObj === null) {
      reqObj = {};
    }
    if (
      requestArray.indexOf(
        "put" + ":" + path + ":" + JSON.stringify(reqObj)
      ) !== -1
    ) {
      return;
    }
    requestArray.push("put" + ":" + path + ":" + JSON.stringify(reqObj));
    return await doRequest("put", path, reqObj, success, error);
  };

  const del = async function (path, reqObj) {
    let deleteReq = {
      data: reqObj,
    };
    if (
      requestArray.indexOf(
        "del" + ":" + path + ":" + JSON.stringify(deleteReq)
      ) !== -1
    ) {
      return;
    }
    requestArray.push("post" + ":" + path + ":" + JSON.stringify(deleteReq));
    return await doRequest("delete", path, deleteReq);
  };

  const doRequest = async function (method, path, reqObj) {
    // Check Internet Explorer browser
    if (
      method === "get" &&
      (navigator.userAgent.indexOf("MSIE") !== -1 ||
        !!document.documentMode === true)
    ) {
      //    IF IE > 10
      // add no-cache option
      client.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      client.defaults.headers.common["Cache-Control"] = "no-cache, no-store";
      // Pragma is for backwards compatibility HTTP/1.0 general header
      client.defaults.headers.common["Pragma"] = "no-cache";
      client.defaults.headers.common["Expires"] = -1;
      client.defaults.headers.post["Content-Type"] = "multipart/form-data";
    }
    if (store.getters["customer/getToken"]) {
      let token = store.getters["customer/getToken"];
      client.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    try {
      const response = await client[method](path, reqObj);
      spliceRequestArray(method + ":" + path + ":" + JSON.stringify(reqObj));
      return response.data;
    } catch (error) {
      spliceRequestArray(method + ":" + path + ":" + JSON.stringify(reqObj));
      if (error.response && error.response.status === 401) {
        // unauthorized request
        router.push("/error");
        return;
      }
      return {
        hasError: true,
        message: error.message ? error.message : "Unexpected Error",
        appData: null,
      };
    }
  };

  const spliceRequestArray = function (value) {
    for (let i = 0; i < requestArray.length; i++) {
      if (requestArray[i] === value) {
        requestArray.splice(i, 1);
      }
    }
  };

  return {
    post: post,
    put: put,
    get: get,
    del: del,
  };
};

export const requestAsync = createRequester(
  process.env.VUE_APP_ORIGIN_API_URL + "/",
  36000
);
