<template>
  <div class="notification-list">
    <h2>THÔNG BÁO</h2>
    <div
      v-for="notification in listNotificationInfoShow"
      :key="notification.id"
      class="notification-card"
    >
      <h4>{{ notification.title }}</h4>
      <div class="content">
        <p>{{ notification.body }}</p>
        <small>{{ notification.created_at }}</small>
      </div>
    </div>
    <pagination
      class="mt-3"
      v-model="pagination.page"
      :per-page="pagination.limit"
      :records="notifications?.length"
      @paginate="myCallback"
    />
  </div>
</template>

<script>
import { requestAsync } from "@/requester/requestAsync";
import { mapGetters } from "vuex";
import EventBus from "@/eventBus";
export default {
  name: "NotificationComp",
  data() {
    return {
      notifications: [],
      pagination: {
        limit: 7,
        order: "desc",
        page: 1,
      },
    };
  },
  computed: {
    listNotificationInfoShow() {
      return this.notifications?.slice(
        (this.pagination.page - 1) * this.pagination.limit,
        this.pagination.page * this.pagination.limit
      );
    },
    ...mapGetters("notification", ["getNotifications"]),
  },
  watch: {
    getNotifications: {
      handler: function (val) {
        this.notifications = val;
      },
      immediate: true,
    },
  },
  methods: {
    myCallback: function (page) {
      console.log(page);
    },
    async setIsReadNotification() {
      let res = await requestAsync.put("notifications/read");
      console.log(res);
      EventBus.$emit("update-list-notification");
    },
  },
  created() {
    this.setIsReadNotification();
  },
};
</script>

<style lang="scss">
@import "@/assets/variable.scss";
.notification-list {
  padding: 15px 15px 20px;
}

.notification-list h2 {
  margin-bottom: 20px;
  color: $primary-color !important;
}

.notification-list ul {
  list-style-type: none;
  padding: 0;
}

.notification-card {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  h4 {
    font-weight: bold;
    width: 250px;
  }
  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
}

.notification-list h4 {
  margin: 0;
  font-size: 1.1em;
}

.notification-list p {
  margin: 5px 0;
  flex: 1;
}

.notification-list small {
  color: #888;
  margin-left: 10px;
  white-space: nowrap;
}
// pagination
.page-link {
  color: $primary-color !important;
}
.disabled > .page-link {
  background: rgba($primary-color, 0.15) !important;
}
.active > .page-link,
.page-link.active {
  background: $primary-color !important;
  color: #fff !important;
  border-color: $primary-color !important;
}
</style>
