const namespaced = true;
const state = {
  notifications: JSON.parse(localStorage.getItem("crm-notifications")) || [],
};

const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications;
    localStorage.setItem("crm-notifications", JSON.stringify(notifications));
  },
};

const getters = {
  getNotifications(state) {
    let localState = window.localStorage.getItem("crm-notifications");
    if (localState) {
      state.notifications = JSON.parse(localState);
    }
    return state.notifications;
  },
};

export default {
  namespaced,
  state,
  mutations,
  getters,
};
