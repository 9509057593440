<template>
  <div id="user-info-detail">
    <div class="list-btn-header d-flex flex-row justify-content-between">
      <div class="button-back">
        <button @click="backToUserManagement()" class="common-btn mx-2">
          Trở về
        </button>
      </div>
      <div class="button-edit-infor">
        <button class="common-btn mx-2" v-b-modal.modal-1>
          Chỉnh sửa thông tin khách hàng
        </button>
      </div>
    </div>
    <div class="user-info-and-required d-flex flex-row justify-content-between">
      <div class="user-info d-flex flex-column">
        <div class="row-info">
          <div class="item-info col-7">
            <div class="label-info">Khách hàng</div>
            <div class="info d-flex flex-row">
              {{ userInfo.name }}
            </div>
          </div>
          <div class="item-info col-5">
            <div class="label-info">Tên công ty / Ngành nghề</div>
            <div class="info d-flex flex-row">
              {{ userInfo.company_name }}
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info col-7">
            <div class="label-info">Số điện thoại</div>
            <div class="info">{{ userInfo.phone }}</div>
          </div>
          <div class="item-info col-5">
            <div class="label-info">Email</div>
            <div class="info">{{ userInfo.email }}</div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info col-7">
            <div class="label-info">Ngày nhập</div>
            <div class="info">
              {{ formatDateTime(userInfo.member_create_customer_at) }}
            </div>
          </div>
          <div class="item-info col-5">
            <div class="label-info">CSKH gần nhất</div>
            <div class="info">
              {{ formatDateTime(userInfo.customer_care_at) }}
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info w-100">
            <div class="label-info">Ghi chú</div>
            <div class="info d-flex flex-row">
              <textarea
                class="w-100 text-sm"
                rows="3"
                disabled
                v-model="userInfo.note"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="required">
        <div class="header-required">NHU CẦU KHÁCH HÀNG</div>
        <div class="body-required">
          <div class="row-info">
            <div class="item-info col-6">
              <div class="label-info">Diện tích</div>
              <div class="info">{{ userInfo?.demand?.expect_area }}</div>
            </div>
            <div class="item-info col-6">
              <div class="label-info">Thời gian sử dụng</div>
              <div class="info">{{ userInfo?.demand?.used_time }}</div>
            </div>
          </div>
          <div class="row-info">
            <div class="item-info col-6">
              <div class="label-info">Ngân sách</div>
              <div class="info">{{ userInfo?.demand?.budget }}</div>
            </div>
            <div class="item-info col-6">
              <div class="label-info">Khu vực</div>
              <div class="info">
                {{ userInfo?.demand?.address?.expect_location }}
              </div>
            </div>
          </div>
          <div class="row-info">
            <div class="item-info col-6">
              <div class="label-info">Số lượng nhân viên</div>
              <div class="info">{{ userInfo?.demand?.num_person }}</div>
            </div>
            <div class="item-info col-6">
              <div class="label-info">Số lượng phương tiện</div>
              <div class="info">
                {{ userInfo?.demand?.vehicle?.car }}
              </div>
            </div>
          </div>
          <div class="row-info">
            <div class="item-info col-6">
              <div class="label-info">Đang ở toà nhà nào</div>
              <div class="info">{{ userInfo?.current_home }}</div>
            </div>
            <div class="item-info col-6">
              <div class="label-info">Yêu cầu đặc biệt</div>
              <div class="info">
                {{ userInfo?.special_note }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-tabs content-class="mt-3">
      <template v-for="(item, index) in userInfo?.member_infos">
        <b-tab :title="item.name" :key="index">
          <div class="services-process">
            <div class="status-info w-100">
              <div class="label-info d-flex align-items-center">
                <span>TÌNH TRẠNG</span>
              </div>
              <b-form-select
                v-model="item.connection_info.status"
                :options="STATUS_OPTIONS_CHECKBOX"
                :disabled="!isMatchMember(item.id)"
              ></b-form-select>
              <button
                class="common-btn mx-2"
                @click="updateUserInfo(userInfo)"
                :disabled="!isMatchMember(item.id)"
              >
                Xác nhận
              </button>
            </div>
            <div class="process-item">
              <div class="no"><span>1</span></div>
              <div class="process-name"><span>QUÁ TRÌNH CHĂM SÓC</span></div>
              <div class="process-detail">
                <template v-if="!updateProcess[index][0]">
                  <textarea
                    disabled="true"
                    rows="4"
                    v-model="item.connection_info.first_step"
                  >
                  </textarea>
                </template>
                <template v-else>
                  <textarea rows="4" v-model="newNoteProcess[index][0]">
                  </textarea>
                </template>
              </div>
              <div class="button-edit" v-if="isMatchMember(item.id)">
                <template v-if="!updateProcess[index][0]">
                  <button
                    class="common-btn d-flex flex-row mx-2"
                    @click="toggleUpdateProcess(index, 0, true)"
                  >
                    <span>Chỉnh sửa</span>
                    <img :src="require('@/assets/images/edit.png')" />
                  </button>
                </template>
                <template v-else>
                  <button
                    class="common-btn d-flex flex-row mx-2"
                    @click="submitProcess(index, 0)"
                  >
                    <span>Xác nhận</span>
                  </button>
                </template>
              </div>
            </div>
            <div class="process-item">
              <div class="no"><span>2</span></div>
              <div class="process-name"><span>DẪN ĐI XEM</span></div>
              <div class="process-detail">
                <template v-if="!updateProcess[index][1]">
                  <textarea
                    disabled="true"
                    rows="4"
                    v-model="item.connection_info.second_step"
                  >
                  </textarea>
                </template>
                <template v-else>
                  <textarea rows="4" v-model="newNoteProcess[index][1]">
                  </textarea>
                </template>
              </div>
              <div class="button-edit" v-if="isMatchMember(item.id)">
                <template v-if="!updateProcess[index][1]">
                  <button
                    class="common-btn d-flex flex-row mx-2"
                    @click="toggleUpdateProcess(index, 1, true)"
                  >
                    <span>Chỉnh sửa</span>
                    <img :src="require('@/assets/images/edit.png')" />
                  </button>
                </template>
                <template v-else>
                  <button
                    class="common-btn d-flex flex-row mx-2"
                    @click="submitProcess(index, 1)"
                  >
                    <span>Xác nhận</span>
                  </button>
                </template>
              </div>
            </div>
            <div class="process-item">
              <div class="no"><span>3</span></div>
              <div class="process-name"><span>THƯƠNG LƯỢNG</span></div>
              <div class="process-detail">
                <template v-if="!updateProcess[index][2]">
                  <textarea
                    disabled="true"
                    rows="4"
                    v-model="item.connection_info.third_step"
                  >
                  </textarea>
                </template>
                <template v-else>
                  <textarea rows="4" v-model="newNoteProcess[index][2]">
                  </textarea>
                </template>
              </div>
              <div class="button-edit" v-if="isMatchMember(item.id)">
                <template v-if="!updateProcess[index][2]">
                  <button
                    class="common-btn d-flex flex-row mx-2"
                    @click="toggleUpdateProcess(index, 2, true)"
                  >
                    <span>Chỉnh sửa</span>
                    <img :src="require('@/assets/images/edit.png')" />
                  </button>
                </template>
                <template v-else>
                  <button
                    class="common-btn d-flex flex-row mx-2"
                    @click="submitProcess(index, 2)"
                  >
                    <span>Xác nhận</span>
                  </button>
                </template>
              </div>
            </div>
            <div class="process-item">
              <div class="no"><span>4</span></div>
              <div class="process-name"><span>CHỐT HỢP ĐỒNG</span></div>
              <div class="process-detail">
                <template v-if="!updateProcess[index][3]">
                  <textarea
                    disabled="true"
                    rows="4"
                    v-model="item.connection_info.fourth_step"
                  >
                  </textarea>
                </template>
                <template v-else>
                  <textarea rows="4" v-model="newNoteProcess[index][3]">
                  </textarea>
                </template>
              </div>
              <div class="button-edit" v-if="isMatchMember(item.id)">
                <template v-if="!updateProcess[index][3]">
                  <button
                    class="common-btn d-flex flex-row mx-2"
                    @click="toggleUpdateProcess(index, 3, true)"
                  >
                    <span>Chỉnh sửa</span>
                    <img :src="require('@/assets/images/edit.png')" />
                  </button>
                </template>
                <template v-else>
                  <button
                    class="common-btn d-flex flex-row mx-2"
                    @click="submitProcess(index, 3)"
                  >
                    <span>Xác nhận</span>
                  </button>
                </template>
              </div>
            </div>
            <div class="process-item" v-if="isMatchMember(item.id)">
              <div class="no"><span>5</span></div>
              <div class="process-name"><span>KẾT THÚC</span></div>
              <template v-if="item?.connection_info?.fifth_step === false">
                <span class="text-notify fail">Đã kết thúc thất bại</span>
              </template>
              <template v-else-if="item?.connection_info?.fifth_step === true">
                <span class="text-notify success">Đã kết thúc thành công</span>
              </template>
              <template v-else>
                <div class="button-confirm">
                  <div class="button-confirm-success">
                    <button
                      @click="submitProcess(index, 4, true)"
                      class="common-btn d-flex flex-row"
                    >
                      <span>Kết thúc thành công</span>
                    </button>
                  </div>
                  <div class="button-confirm-fail">
                    <button
                      @click="submitProcess(index, 4, false)"
                      class="common-btn fail-btn d-flex flex-row mx-5"
                    >
                      <span>Kết thúc thất bại</span>
                    </button>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </b-tab>
      </template>
    </b-tabs>
    <userInfoModal
      :userInfo="userInfo"
      @updateUserInfo="updateUserInfo"
      ref="userInfoModal"
      title="Cập nhập khách hàng"
    ></userInfoModal>
  </div>
</template>

<script>
import { requestAsync } from "@/requester/requestAsync";
import listStatus from "@/ultils/listStatus";
import moment from "moment";
import userInfoModal from "@/components/common/UserInfoModal.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    userInfoModal,
  },
  data() {
    return {
      userInfo: {},
      newNoteProcess: [],
      selectedUserInfoId: null,
      updateProcess: [],
      listStep: ["first_step", "second_step", "third_step", "fourth_step"],
      STATUS_OPTIONS_CHECKBOX: listStatus,
    };
  },
  computed: {
    ...mapGetters("customer", ["isAdmin", "getMemberInfo"]),
  },
  methods: {
    formatDateTime(dateTime) {
      return moment(dateTime).format("DD/MM/YYYY");
    },
    isMatchMember(userIdOfInfo) {
      if (this.isAdmin) return true;
      else {
        return userIdOfInfo === this.getMemberInfo.id;
      }
    },
    backToUserManagement() {
      this.$router.push("/user-management");
    },
    async updateUserInfo(data) {
      for (let member of data.member_infos) {
        member.connection_info.fifth_step = null;
      }
      // auto update updated date time
      data.customer_care_at = moment().format("YYYY-MM-DD");
      let res = await requestAsync.put(
        "customers/" + this.selectedUserInfoId,
        data
      );
      if (res?.id) {
        // return true
        this.$toast.open({
          message: "Chỉnh sửa thông tin khách hàng thành công!",
          type: "success",
          position: "top",
          duration: 2000,
        });
      } else {
        this.$toast.open({
          message: "Chỉnh sửa thông tin khách hàng thất bại!",
          type: "error",
          position: "top",
          duration: 2000,
        });
      }
      this.getDataUserInfo();
      this.$refs.userInfoModal.$refs.modalInnerUserInfo.hide();
    },
    updateConnectStep(listStep) {
      for (let member of this.userInfo.member_infos) {
        for (let step of listStep) {
          if (!(step in member.connection_info)) {
            member.connection_info[step] = "";
          }
        }
      }
    },
    prepareDataForUpdate() {
      this.updateProcess = this.userInfo.member_infos.map(() => [
        false,
        false,
        false,
        false,
      ]);
      this.newNoteProcess = this.userInfo.member_infos.map(() => [
        "",
        "",
        "",
        "",
      ]);
      this.userInfo.member_infos.forEach((obj) => {
        if (!obj.connection_info) {
          obj.connection_info = {
            first_step: "",
            second_step: "",
            third_step: "",
            fourth_step: "",
            fifth_step: null,
          };
        }
      });
    },
    async getDataUserInfo() {
      // to do
      let res = await requestAsync.get("customers/" + this.selectedUserInfoId);
      if (res?.id) {
        this.$set(this, "userInfo", res);
        this.prepareDataForUpdate();
        this.updateConnectStep(this.listStep);
      }
    },
    updateStatusUser(value) {
      this.userInfo.status = value === true ? "SUCCESS" : "FAIL";
    },
    updateNoteToProcess(memberNum, position) {
      switch (true) {
        case position === 0 && this.newNoteProcess[memberNum][0].length > 0:
          if (
            this.userInfo.member_infos[memberNum].connection_info.first_step
              .length > 0
          ) {
            this.userInfo.member_infos[memberNum].connection_info.first_step +=
              "\n" + this.newNoteProcess[memberNum][0];
          } else {
            this.userInfo.member_infos[memberNum].connection_info.first_step =
              this.newNoteProcess[memberNum][0];
          }
          this.newNoteProcess[memberNum][0] = "";
          break;
        case position === 1 && this.newNoteProcess[memberNum][1].length > 0:
          if (
            this.userInfo.member_infos[memberNum].connection_info.second_step
              .length > 0
          ) {
            this.userInfo.member_infos[memberNum].connection_info.second_step +=
              "\n" + this.newNoteProcess[memberNum][1];
          } else {
            this.userInfo.member_infos[memberNum].connection_info.second_step =
              this.newNoteProcess[memberNum][1];
          }
          this.newNoteProcess[memberNum][1] = "";
          break;
        case position === 2 && this.newNoteProcess[memberNum][2].length > 0:
          if (
            this.userInfo.member_infos[memberNum].connection_info.third_step
              .length > 0
          ) {
            this.userInfo.member_infos[memberNum].connection_info.third_step +=
              "\n" + this.newNoteProcess[memberNum][2];
          } else {
            this.userInfo.member_infos[memberNum].connection_info.third_step =
              this.newNoteProcess[memberNum][2];
          }
          this.newNoteProcess[memberNum][2] = "";
          break;
        case position === 3 && this.newNoteProcess[memberNum][3].length > 0:
          if (
            this.userInfo.member_infos[memberNum].connection_info.fourth_step
              .length > 0
          ) {
            this.userInfo.member_infos[memberNum].connection_info.fourth_step +=
              "\n" + this.newNoteProcess[memberNum][3];
          } else {
            this.userInfo.member_infos[memberNum].connection_info.fourth_step =
              this.newNoteProcess[memberNum][3];
          }
          this.newNoteProcess[memberNum][3] = "";
          break;
      }
    },
    async submitProcess(memberNum, position, value = null) {
      if (position === 4) {
        this.userInfo[memberNum].connection_info.fifth_step = value;
        this.updateStatusUser(value);
      } else {
        this.toggleUpdateProcess(memberNum, position, false);
        this.updateNoteToProcess(memberNum, position);
      }
      this.updateUserInfo(this.userInfo);
    },
    toggleUpdateProcess(memberNum, position, status) {
      this.$set(this.updateProcess[memberNum], position, status);
    },
    getStatusName(status) {
      switch (true) {
        case status === "NEW":
          return "Mới";
        case status === "INACCESSIBLE":
          return "Khó tiếp cận";
        case status === "QUOTE":
          return "Báo giá";
        case status === "WATCHED":
          return "Đi xem";
        case status === "NEGOTIATION":
          return "Thương lượng";
        case status === "PENDING":
          return "Tạm hoãn";
        case status === "SUCCESS":
          return "Thành công";
        case status === "FAIL":
          return "Thất bại";
      }
    },
  },
  created() {
    this.selectedUserInfoId = this.$route.params.id;
    this.getDataUserInfo();
  },
};
</script>

<style lang="scss">
@import "@/assets/variable.scss";
#user-info-detail {
  padding: 20px 30px;
  .list-btn-header {
  }
  .user-info-and-required {
    .user-info,
    .required {
      margin-top: 30px;
      .user-info {
        background: #fff;
        padding: 15px 20px;
      }
      .row-info {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .text-sm {
          font-size: 16px;
          padding: 10px;
        }
        .item-info {
          .label-info {
            color: $primary-color;
            font-size: 16px;
          }
          .info {
            font-size: 22px;
            white-space: normal;
            word-wrap: break-word; /* Dùng thuộc tính cũ */
            overflow-wrap: break-word; /* Dùng thuộc tính mới */
            .status {
              color: #00813c;
            }
          }
        }
        .select-item {
          display: flex;
          .custom-select {
            margin-left: 20px;
            padding: 5px;
          }
        }
      }
    }
  }
  .user-info {
    width: 45%;
    padding: 10px 20px 30px 20px;
    background: #fff;
  }
  .required {
    width: 52%;
    background: #fff;
    .header-required {
      background: rgba($primary-color, 0.6);
      padding: 20px;
      color: #fff;
      font-size: 18px;
    }
    .body-required {
      padding: 0px 20px;
    }
  }
  .tabs {
    margin-top: 30px;
  }
  .services-process {
    padding: 20px;
    margin: 20px 0px;
    background: #fff;
    .status-info {
      display: flex;
      flex-direction: row;
      gap: 20px;
      .label-info {
        margin-left: 70px;
        width: 230px;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .process-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 15px 0;
      .no {
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $secondary-color;
        color: #fff;
        display: flex;
        justify-content: center;
        span {
          padding-top: 8px;
          font-size: 24px;
        }
      }
      .process-name,
      .process-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .process-name {
        min-width: 250px;
        padding-left: 20px;
        font-size: 20px;
        font-weight: bolder;
      }
      .process-detail {
        flex-grow: 1;
        min-height: 80px;
        margin: 0px 20px;
        background: #f5f5f5;
        border-radius: 3px;
        textarea {
          padding: 5px 10px;
        }
      }
      .text-notify {
        font-size: 20px;
        margin-left: 20px;
      }
      .success {
        color: #00813c;
      }
      .fail {
        color: red;
      }
      .button-edit {
        min-width: 155px;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .button-confirm {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        .fail-btn {
          background: $primary-color;
        }
      }
    }
  }
}
</style>
