<template>
  <div id="notification-page" class="w-100">
    <NotificationComp></NotificationComp>
  </div>
</template>

<script>
import NotificationComp from "@/components/NotificationComp.vue";
export default {
  components: { NotificationComp },
};
</script>

<style></style>
